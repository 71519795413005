import {
  IOverflowSetItemProps,
  OverflowSet,
  mergeStyleSets,
} from "@fluentui/react";
import { useContext, useMemo } from "react";
import { useSelector } from "../../store/hooks";
import { darkTheme, lightTheme } from "../../utils/utils";
import { CrmSideBarContext } from "./CrmSideBarContext";
import { CrmSidebarData } from "./CrmSideBarData";
import { onRenderOverflowButton } from "./SideBar/SideBar";
import SidebarIconComponent from "./SidebarIconComponent/SidebarIconComponent";

export const headerHeight = 50;
export const sideBarWidth = 55;
export const sideBarMargin = 50;

export const sideBarStyle: any = {
  position: "fixed",
  top: headerHeight,
  zIndex: 9995,
  height: "100%",
  width: sideBarWidth,
};

const rvmClasses = mergeStyleSets({
  light: {
    backgroundColor: "#d3d3d3",
  },
  dark: {
    backgroundColor: "#000",
  },
});

export const CrmSidebar = (): JSX.Element => {
  const isDark2 = localStorage.getItem("darkMode") === "true";
  const theme = isDark2 ? darkTheme : lightTheme;
  const crmSideBarContext = useContext(CrmSideBarContext);
  const taxonomy = useSelector((s) => s.taxonomy);

  const getObjectType = useMemo(() => {
    return () => {
      // if (crmSideBarContext.state.url === "romWebReports") {
      //   return crmSideBarContext.state.typeId;
      // }
      // return 0;//getTaxonomyItemCode(
      //   "BusinessObjectTypeCode",
      //   crmSideBarContext.state.typeId
      // );
      //return "BusinessObjectType.Location"
      if (
        crmSideBarContext &&
        crmSideBarContext.state.typeId !== "" &&
        taxonomy.BusinessObjectType.items.length > 0
      ) {
        const retVal =
          taxonomy.BusinessObjectType.byId[crmSideBarContext.state.typeId].code;
        return retVal;
      } else {
        return "BusinessObjectType.CompanyClient";
      }
      // );
      //return "BusinessObjectType.CompanyClient";
    };
  }, [crmSideBarContext, taxonomy]);

  return (
    <>
      {/* {crmSideBarContext.state.url ? ( */}
      <OverflowSet
        role="menubar"
        vertical
        styles={{
          root: {
            ...sideBarStyle,
            backgroundColor: theme.palette.neutralLight,
          },
        }}
        items={CrmSidebarData({
          type: getObjectType(),
          id: crmSideBarContext.state.id,
        })}
        onRenderOverflowButton={onRenderOverflowButton}
        onRenderItem={(item: IOverflowSetItemProps) => {
          return onRenderItem(item, crmSideBarContext.state.url, isDark2);
        }}
      />
      {/* ) : null} */}
    </>
  );
};

const onRenderItem = (
  item: IOverflowSetItemProps,
  selected: string,
  isDark: boolean
): JSX.Element => {
  const itemClassName = item.disabled
    ? "icon-container-disabled"
    : item.key === selected
    ? "icon-container-current " + (isDark ? rvmClasses.dark : rvmClasses.light)
    : "icon-container";
  return <SidebarIconComponent item={item} itemClassName={itemClassName} />;
};
