import { useTheme } from "@emotion/react";
import {
  CommandBar,
  CommandBarButton,
  DefaultButton,
  IContextualMenuProps,
  IIconProps,
  IObjectWithKey,
  Selection,
  mergeStyleSets,
} from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getConfigurationSettings } from "../../api/settingConfigurationApi";
import NavigationPanel from "../../components/NavigationPanel/NavigationPanel";
import { HSpace, Right } from "../../components/styled";
import useDocCommandBarItems from "../../hooks/useDocCommandBarItems";
import useIsInternalUser from "../../hooks/useIsInternalUser";
import { useSelector } from "../../store/hooks";
import {
  setExcelDialog,
  setMailConfiguration,
  setShowInvitationForm,
  setShowReminderForm,
} from "../../store/locations/locations";
import { AppDispatch } from "../../store/store";
import { EConfigurationSetting } from "../../types/types";
import Campaign from "./Campaign/Campaign";
import ConfigureDocSettings from "./ConfigureDocSettings/ConfigureDocSettings";
import { Search } from "./Search";

type Props = {
  tableSelection: Selection<IObjectWithKey>;
  handleShowApproveForm: () => void;
  handleShowRejectForm: () => void;
};

function LocationsHeader({
  tableSelection,
  handleShowApproveForm,
  handleShowRejectForm,
}: Props) {
  const docCommandBarItems = useDocCommandBarItems();
  const campaignInfo = useSelector((s) => s.campaign.campaignInfo);
  const campaignIsClosed = campaignInfo?.isCampaignClosed || !campaignInfo;

  const { t } = useTranslation();
  const client = useSelector((s) => s.clients.client);
  const clientId = client?.clientId;
  const theme = useTheme();
  const selection = useSelector((s) => s.locations.selection);
  const locations = useSelector((s) => s.locations.locations);
  const mailConfig = useSelector((s) => s.locations.mailConfiguration);

  const isInternalUser = useIsInternalUser();

  const taxonomy = useSelector((s) => s.taxonomy);
  const [navigation, setNavigationOpen] = useState(false);

  const cancelIcon: IIconProps = { iconName: "Cancel" };
  const excelIcon: IIconProps = { iconName: "ExcelLogo" };
  const configDocIcon: IIconProps = { iconName: "MailOptions" };

  const classes = getClasses(theme);
  const dispatch: AppDispatch = useDispatch();

  const handleToggleNavOpen = useCallback(() => {
    setNavigationOpen((prev) => !prev);
  }, []);

  const canSendInvitation: boolean = useMemo(() => {
    if (!selection || selection.length === 0) return false;
    return selection.reduce((acc, item) => {
      return (
        acc &&
        item &&
        (taxonomy?.PropertyInformationStatus.byId[item.location.statusCode]
          .code === "PropertyInformationStatus.DONE" ||
          taxonomy?.PropertyInformationStatus.byId[item.location.statusCode]
            .code === "PropertyInformationStatus.NO_DATA") &&
        !item.isBindToLocalLocation
      );
    }, true);
  }, [selection, taxonomy]);

  const canOpenCampaign: boolean = useMemo(() => {
    return locations.every((item) => {
      return (
        (taxonomy?.PropertyInformationStatus.byId[item.location.statusCode]
          .code === "PropertyInformationStatus.DONE" ||
          taxonomy?.PropertyInformationStatus.byId[item.location.statusCode]
            .code === "PropertyInformationStatus.NO_DATA" ||
          taxonomy?.PropertyInformationStatus.byId[item.location.statusCode]
            .code === "PropertyInformationStatus.DISABLED") &&
        !item.isBindToLocalLocation
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignIsClosed, selection, taxonomy?.PropertyInformationStatus.byId]);

  const canSendReminder: boolean = useMemo(() => {
    if (!selection || selection.length === 0) return false;

    return selection.reduce((acc, item) => {
      return (
        acc &&
        item &&
        taxonomy?.PropertyInformationStatus.byId[item.location.statusCode]
          .code === "PropertyInformationStatus.INVITATION_SENT" &&
        !item.isBindToLocalLocation
      );
    }, true);
  }, [selection, taxonomy]);

  const canApproveOrCancelLocations: boolean = useMemo(() => {
    if (!selection || selection.length === 0) return false;

    return selection.every((item) => {
      return (
        item &&
        taxonomy?.PropertyInformationStatus.byId[item.location.statusCode]
          .code === "PropertyInformationStatus.REVIEW" &&
        !item.isBindToLocalLocation
      );
    });
  }, [selection, taxonomy]);

  const fetchMailLocationConfigurationSettings = async (clientId) => {
    const response = await getConfigurationSettings({
      clientId,
      settingName: EConfigurationSetting.Email,
    });

    const data = response
      ? {
          ...response,
          settingValue: JSON.parse(response.settingValue),
        }
      : null;
    dispatch(setMailConfiguration(data));
  };

  const configMenuProps: IContextualMenuProps = useMemo(
    () => ({
      shouldFocusOnMount: true,
      shouldFocusOnContainer: true,
      items: [
        {
          key: "configMailLocations",
          text: t("rvm.configMailLocations.label"),
          onRender: () => {
            return (
              <CommandBarButton
                disabled={!clientId}
                onClick={handleToggleNavOpen}
                className={classes.buttonClass}
                iconProps={configDocIcon}
                text={t("rvm.configMailLocations.label")}
              />
            );
          },
        },
        {
          key: "configureDocSettings",
          text: t("rvm.configDocuments.label"),

          onRender: () => {
            return <ConfigureDocSettings />;
          },
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientId]
  );

  useEffect(() => {
    if (!clientId) return;
    fetchMailLocationConfigurationSettings(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  return (
    <>
      <div className={classes.navigation}>
        <Search selection={tableSelection} />
        {isInternalUser && (
          <>
            <DefaultButton
              styles={{
                root: {
                  border: "none",
                },
                label: {
                  fontWeight: 400,
                },
              }}
              iconProps={{
                iconName: "Settings",
                style: {
                  color: theme.palette.themePrimary,
                },
              }}
              text={t("rvm.configurations.label")}
              menuProps={configMenuProps}
            />
          </>
        )}
        <div className={classes.documentsCommandBarWrap}>
          <CommandBar
            onReduceData={() => undefined}
            className={classes.documentsCommandBar}
            items={docCommandBarItems}
            overflowItems={[]}
          />
        </div>
        <CommandBarButton
          disabled={!clientId}
          onClick={() => {
            dispatch(
              setExcelDialog({
                isOpen: true,
                title: t("rvm.generateExcelReport.label"),
                icon: "ExcelLogo",
              })
            );
          }}
          className={classes.buttonClass}
          iconProps={excelIcon}
          text={t("rvm.generateExcelReport.label")}
        />
        {isInternalUser && <Campaign disableOpenCampaign={!canOpenCampaign} />}

        <Right>
          {selection?.length > 0 ? (
            <>
              <CommandBarButton
                onClick={() => {
                  tableSelection.setAllSelected(false);
                }}
                className={classes.buttonClass}
                iconProps={cancelIcon}
                text={`${selection?.length} ` + t("rvm.selected.label")}
              />
              <HSpace width={10} />
            </>
          ) : null}
          <CommandBarButton
            onClick={() => {
              dispatch(setShowInvitationForm(true));
            }}
            className={classes.buttonClass}
            iconProps={{ iconName: "Send" }}
            text={t("rvm.sendInvitation.label")}
            disabled={!canSendInvitation || campaignIsClosed}
          />
          <CommandBarButton
            onClick={() => {
              dispatch(setShowReminderForm(true));
            }}
            className={classes.buttonClass}
            iconProps={{ iconName: "MailReminder" }}
            text={t("rvm.sendReminder.label")}
            disabled={!canSendReminder}
          />
          <CommandBarButton
            onClick={handleShowApproveForm}
            className={classes.buttonClass}
            iconProps={{ iconName: "DocumentApproval" }}
            text={t("rvm.approve.label")}
            disabled={!canApproveOrCancelLocations}
          />
          <CommandBarButton
            onClick={handleShowRejectForm}
            className={classes.buttonClass}
            iconProps={{ iconName: "DocumentReply" }}
            text={t("rvm.reject.label")}
            disabled={!canApproveOrCancelLocations || campaignIsClosed}
          />
        </Right>
      </div>
      <NavigationPanel
        handleToggleNavOpen={handleToggleNavOpen}
        showPanel={navigation}
        selectedLocations={tableSelection}
        title="Save To"
        originLocationInfo={mailConfig?.settingValue}
        siteLocation={{
          cid: "300000000754535",
          ctype: "Partner",
          vFolder: 300000002204765,
        }}
      />
    </>
  );
}

export default LocationsHeader;

const getClasses = (theme) =>
  mergeStyleSets({
    navigation: {
      height: "44px",
      background: theme.palette.white,
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "0 25px",
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      position: "sticky",
      top: 0,
      zIndex: 999,
    },
    buttonClass: {
      display: "flex",
      alignItems: "center",
      height: 32,
      ".ms-Button-icon": {
        height: "inherit",
        display: "flex",
        alignItems: "center",
      },
    },
    documentsCommandBarWrap: {},
    documentsCommandBar: {
      "& .ms-CommandBar": {
        height: "32px",
        padding: "0px 4px",
      },
    },
  });
