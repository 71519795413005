import { IconButton, Persona, PersonaSize } from "@fluentui/react";
import { useState } from "react";
// import { setIsRightSidebarOpen } from "src/store/ui/ui";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { zIndex } from "../../config/zIndex";
import { useSelector } from "../../store/hooks";
import { lightenDarkenColor } from "../../utils";
import { useClickOutside } from "../../utils/useClickOutside";
import { darkTheme, lightTheme } from "../../utils/utils";
import { AuthSidebar } from "./AuthSidebar";

const theme =
  localStorage.getItem("darkMode") === "true" ? darkTheme : lightTheme;

export const UserPersona = () => {
  const { t } = useTranslation();
  // const isInsuredPersonsLoading = useSelector(
  //   (state) => state.loading.insuredPersons
  // );
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const refRoot = useClickOutside(() => {
    if (isSidebarOpen) {
      setSidebarOpen(false);
    }
  });
  const email = useSelector((s) => s.userRole.email);

  // if (isInsuredPersonsLoading || !isLangChecked) {
  //   return <Spinner size={SpinnerSize.large} style={{ marginRight: 5 }} />;
  // }

  return (
    <>
      <StyledPersona
        text={email}
        hidePersonaDetails
        size={PersonaSize.size40}
        isOpen={isSidebarOpen}
        onClick={() => setSidebarOpen(!isSidebarOpen)}
        initialsTextColor={"#ffffff"}
      />
      {isSidebarOpen ? <AuthSidebar /> : null}
    </>
  );
};

const StyledPersona = styled(Persona)<{ isOpen: boolean }>`
  cursor: pointer;
  user-select: none;
  height: 100%;
  padding: 5px;
  z-index: ${zIndex.rightSidebarButton};
  flex-shrink: 0;
  &:hover {
    background-color: white;
  }
  ${(p) =>
    p.isOpen &&
    css`
      background-color: white;
    `};
`;

const ArrowButton = styled(IconButton)`
  background-color: ${theme.palette.themePrimary};
  border-radius: 50%;
  padding: 20px;
  margin-left: auto;
  color: white;
  &:hover {
    background-color: ${(p) =>
      lightenDarkenColor(theme.palette.themePrimary, 20)};
    color: white;
  }
`;

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: white;
  font-size: 14px;
  padding: 8px;
  margin-right: 30px;
  &.active {
    border-color: #4ce6ff;
  }
`;
