import { Dialog as FDialog, Icon } from "@fluentui/react";
import { css } from "emotion";
import { useDispatch } from "react-redux";
import { Flex, HSpace } from "../../../components/styled";
import { useSelector } from "../../../store/hooks";
import {
  setExcelDialog,
  setExcelReportResponse,
} from "../../../store/locations/locations";
import GenerateExcelReportFormDialog from "./GenerateExcelReportFormDialog";

export const Dialog = () => {
  const dialog = useSelector((s) => s.locations.excelDialog);
  const dispatch = useDispatch();

  if (!dialog.isOpen) return null;

  const close = () => {
    dispatch(setExcelDialog({ isOpen: false }));
    dispatch(setExcelReportResponse(null));
  };

  const Title = (
    <Flex>
      <Icon
        className={css`
          color: ${dialog.color};
        `}
      />
      <HSpace />
      <div>{dialog.title}</div>
    </Flex>
  );

  let dialogContent = <GenerateExcelReportFormDialog />;

  return (
    <FDialog
      hidden={false}
      modalProps={{
        isBlocking: true,
      }}
      dialogContentProps={{
        title: Title,
        showCloseButton: true,
      }}
      minWidth={500}
      onDismiss={close}
      // isBlocking
      className={css`
        min-height: 500px;
        .ms-Dialog-inner {
          padding-bottom: 10px;
        }
        .ms-Dialog-title {
          padding-bottom: 10px;
        }
      `}
    >
      {dialogContent}
    </FDialog>
  );
};
