import { CommandBar } from "@fluentui/react";
import useDocCommandBarItems from "../../../hooks/useDocCommandBarItems";

function ExternalUserCommandBar() {
  const commandBarItems = useDocCommandBarItems();

  return (
    <div style={{ width: "670px" }}>
      <CommandBar items={commandBarItems as any} overflowItems={[]} />
    </div>
  );
}

export default ExternalUserCommandBar;
