import { css as cssClass } from "@emotion/css";
import { formatISO } from "date-fns";
import { Formik } from "formik";
import moment from "moment";

import {
  Dialog,
  DialogFooter,
  DialogType,
  mergeStyleSets,
} from "@fluentui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { FormSpinner } from "../../../components/FormSpinner";
import PinLocationsList from "../../../components/PinLocationsList";
import DatePickerField from "../../../components/controls/DatePickerField";
import DialogButton from "../../../components/controls/DialogButton";
import { Field } from "../../../components/form/Field";
import { useSelector } from "../../../store/hooks";
import {
  addPropertyInfoActionsRoutine,
  loadLocationsForClientRoutine,
} from "../../../store/locations/locations";

type Props = {
  close: () => void;
  values: any;
  selection: any;
  coveragePeriodFromDate: any;
  coveragePeriodToDate: any;
};

const SendInvitationFormDialog = ({
  close,
  values,
  selection,
  coveragePeriodFromDate,
  coveragePeriodToDate,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const isLoading =
    useSelector((s) => s.valueMonitoring.dataLoadStatus) === "loading";
  const clientId = useSelector((s) => s.clients.clientId);
  const locationsSelection = useSelector((s) => s.locations.selection);

  const onSubmit = async (data) => {
    const formObject = values.map((value) => {
      return {
        desiredLocationOwningStatusCode: value.location.locationOwningStatus,
        desiredLocationOwningStatusDate: value.location.locationOwningStatusDate
          ? formatISO(
              moment(value.location.locationOwningStatusDate).toDate(),
              {
                representation: "date",
              }
            )
          : "",
        desiredLocationStatusCode: value.location.locationStatus,
        desiredLocationStatusDate: value.location.locationStatusDate
          ? formatISO(moment(value.location.locationStatusDate).toDate(), {
              representation: "date",
            })
          : "",

        grECoComment: data.comment,
        coveragePeriodFromDate: formatISO(data.coveragePeriodFromDate, {
          representation: "date",
        }),
        coveragePeriodToDate: formatISO(data.coveragePeriodToDate, {
          representation: "date",
        }),
        currencyCode: value.location.currencyCode,
        clientId: value.clientId,
        locationId: value.locationId,
      };
    });
    await dispatch(
      addPropertyInfoActionsRoutine.trigger({
        formObject,
        onSuccess: () => {
          dispatch(loadLocationsForClientRoutine.trigger({ clientId }));
          selection.setAllSelected(false);
        },
      })
    );
    selection.setAllSelected(false);
    close();
  };

  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {t("rvm.sendInvitation.label")}
    </div>
  );
  const validationSchema = useMemo(() => {
    const schemaObj = {
      coveragePeriodFromDate: yup.date().required(),
      coveragePeriodToDate: yup.date().required(),
      comment: yup.string().max(512).nullable(),
    };
    return yup.object().shape(schemaObj);
  }, []);

  const formStyles = mergeStyleSets({
    containerOne: {
      display: "grid",
      gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
      gridColumnGap: "20px",
      gridRowGap: "20px",
      padding: "20px",
      paddingBottom: "30px",
      "& .grid-item0": {
        gridColumn: "1 / span 1",
      },
      "& .grid-item1": {
        gridColumn: "2 / span 1",
        height: "280px",
        overflowY: "scroll",
      },

      // "& .grid-item12": {
      //   gridColumn: "1 / span 3",
      // },
    },

    containerGrid50: {
      display: "flex",
      gap: "20px",
      justifyContent: "flex-start",
      width: "66%",
    },
    containerGrid50Item: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
    },
    containerGrid: {
      display: "flex",
      gap: "20px",
      justifyContent: "flex-start",
    },
    containerGridGrow: {
      flexGrow: "1",
    },
    boldSpan: {
      fontWeight: "600",
    },
    infoLabel: {
      margin: "50px 0px",
      float: "right",
      color: "red",
    },
  });

  const allLocationsHaveContactPersons = locationsSelection.reduce(function (
    accumulator,
    currentValue
  ) {
    return (
      currentValue &&
      accumulator &&
      (currentValue.location.contactPersons.length === 0 ? false : true)
    );
  },
  true);

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        className: cssClass`
          .ms-Dialog-title {
            margin: 20px;
            padding-bottom: 10px;
          }
          .ms-Dialog-inner {
            margin: 20px;
            padding-bottom: 14px;
          }
          .ms-Button {
            padding: 10px 35px;
            //background: rgb(0, 184, 238);
          }
        `,
        showCloseButton: true,
      }}
      minWidth={800}
      modalProps={{
        isBlocking: true, //phraseActivated ? false :
      }}
    >
      <Formik
        initialValues={{
          comment: "",
          coveragePeriodFromDate: null,
          coveragePeriodToDate: null,
        }}
        onSubmit={onSubmit}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm, errors, setFieldValue }) => {
          return (
            <>
              <div className={formStyles.containerGrid50}>
                <div className={formStyles.containerGrid50Item}>
                  <span className={formStyles.boldSpan}>From&nbsp;*</span>
                  <DatePickerField
                    errors={errors}
                    setFieldValue={setFieldValue}
                    defaultValue={coveragePeriodFromDate}
                    name={"coveragePeriodFromDate"}
                    disabled={false}
                    label={""}
                    placeholder={t("rvm.selectDate")}
                    isMarked={false}
                  />
                </div>
                <div className={formStyles.containerGrid50Item}>
                  <span className={formStyles.boldSpan}>To&nbsp;*</span>
                  <DatePickerField
                    errors={errors}
                    setFieldValue={setFieldValue}
                    defaultValue={coveragePeriodToDate}
                    name={"coveragePeriodToDate"}
                    disabled={false}
                    label={""}
                    placeholder={t("rvm.selectDate")}
                    isMarked={false}
                  />
                </div>
              </div>
              <div className={formStyles.containerGrid}>
                <div className={formStyles.containerGridGrow}>
                  <PinLocationsList selection={locationsSelection} />
                </div>
              </div>
              {allLocationsHaveContactPersons ? (
                <div className={formStyles.containerGrid}>
                  <div className={formStyles.containerGridGrow}>
                    <Field
                      name="comment"
                      type="text"
                      label={t("rvm.clientComment.label")}
                      isMultiline
                      rows={1}
                      isRequired={false}
                    />
                  </div>
                </div>
              ) : null}
              {allLocationsHaveContactPersons ? (
                <DialogFooter>
                  <DialogButton
                    text={t("rvm.send.label")}
                    disabled={isLoading}
                    operationFn={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                  />
                </DialogFooter>
              ) : (
                <div className={formStyles.infoLabel}>
                  {t("rvm.locationMustHaveContactPersion.label")}
                </div>
              )}
              {isLoading && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default SendInvitationFormDialog;
