import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useTranslation } from "react-i18next";
import { darkTheme, lightTheme } from "../../../utils/utils";

interface IProps {
  label: string;
  children: JSX.Element | string;
  fieldName: string;
}
const theme =
  localStorage.getItem("darkMode") === "true" ? darkTheme : lightTheme;

const romClasses = mergeStyleSets({
  keyText: {
    marginRight: "6px",
    whiteSpace: "nowrap",
  },
  keyValueWrap: {
    marginBottom: "10px",
    display: "grid",
    gridTemplateColumns: "40% 60%",
    wordBreak: "break-word",
  },
  valueItem: {
    wordBreak: "break-word",
    fontWeight: "600",
  },
  flex: {
    display: "flex",
  },
  isMarked: {
    backgroundColor: theme.palette.yellowLight,
    color: "black",
    display: "initial",
  },
});
const ReadOnlyValuePair = ({ label, children, fieldName }: IProps) => {
  const { t } = useTranslation();
  return (
    <div data-qa={fieldName} className={`${romClasses.keyValueWrap}`}>
      <span className={`${romClasses.keyText}`}>
        <p className={`${""}`}>{t(label)}</p>
      </span>
      <span className={romClasses.valueItem}>
        {" "}
        <p className={`${""}`}>{children}</p>
      </span>
    </div>
  );
};

export default ReadOnlyValuePair;
