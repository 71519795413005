import { css } from "@emotion/react";
import { DefaultButton, Icon, Modal, PrimaryButton } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { searchLocationByPoint } from "../../../api/maps";
import { getPinIcon } from "../../../assets/getPinIcon";
import { useSelector } from "../../../store/hooks";
import countries from "../../../utils/countries.json";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  setFieldValue: any;
  location: any;
};

export const MapModal = ({
  isOpen,
  closeModal,
  setFieldValue,
  location,
}: Props) => {
  // const { setFieldValue, values } = useFormikContext();
  const areBingMapsReady = useSelector((s) => s.bingMaps.areBingMapsReady);
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const [loc, setLoc] = useState<{
    address: any | null;
    lat: number | null;
    long: number | null;
  }>({
    address: null,
    lat: null,
    long: null,
  });
  // const location = useSelector((s) => s.valueMonitoring.entities[locationId]);

  useEffect(() => {
    if (!areBingMapsReady) return;
    if (!isOpen) return;
    setTimeout(() => {
      var center =
        location && location.latitude && location.longitude
          ? new Microsoft.Maps.Location(location.latitude, location.longitude)
          : null;

      window.map = new Microsoft.Maps.Map("#bing-map", {
        showLocateMeButton: false,
        navigationBarMode: Microsoft.Maps.NavigationBarMode.minified,
        center: center,
      });

      if (center) {
        var pin = new Microsoft.Maps.Pushpin(center, {
          title: location.formattedAddress,
          icon: getPinIcon(30, 45, "#005aa1"),
        });
        window.map.entities.push(pin);
      }

      const onPlacePin = async (e: any) => {
        window.map.entities.clear();
        const pin = new Microsoft.Maps.Pushpin(
          new Microsoft.Maps.Location(
            e.location.latitude,
            e.location.longitude
          ),
          {
            icon: getPinIcon(30, 45, "#005aa1"),
            title: "",
            draggable: true,
          }
        );
        Microsoft.Maps.Events.addHandler(pin, "dragend", function (e) {
          onPlacePin(e);
        });
        window.map.entities.push(pin);

        const locRes = await searchLocationByPoint(
          e.location.latitude,
          e.location.longitude
        );

        pin.setOptions({
          title: locRes[0].address.addressLine || locRes[0].address.locality,
        });

        setLoc({
          address: locRes[0].address,
          lat: locRes[0].geocodePoints[0].coordinates[0],
          long: locRes[0].geocodePoints[0].coordinates[1],
        });
      };
      Microsoft.Maps.Events.addHandler(window.map, "click", onPlacePin);
      function suggestionSelected(result: any) {
        //Remove previously selected suggestions from the map.
        window.map.entities.clear();
        //Show the suggestion as a pushpin and center map over it.
        const pin = new Microsoft.Maps.Pushpin(result.location, {
          icon: getPinIcon(30, 45, "#005aa1"),
          title: result.title,
        });
        window.map.entities.push(pin);

        window.map.setView({ bounds: result.bestView });

        setLoc({
          address: result.address,
          lat: result.location?.latitude,
          long: result.location?.longitude,
        });
      }
      Microsoft.Maps.loadModule("Microsoft.Maps.AutoSuggest", function () {
        const manager = new Microsoft.Maps.AutosuggestManager({
          map: window.map,
        });
        manager.attachAutosuggest(
          "#searchBox",
          "#searchBoxContainer",
          suggestionSelected
        );
        const searchBox = document.getElementById("searchBox");
        if (searchBox) {
          searchBox.style.boxSizing = "border-box";
        }
      });
    }, 0);
  }, [areBingMapsReady, isOpen]);

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => {
        closeModal();
      }}
      isBlocking={false}
    >
      <div
        css={css`
          position: absolute;
          top: 18px;
          z-index: 99;
          background: white;
          width: 500px;
          left: 20px;
        `}
      >
        <SearchBoxContainer id="searchBoxContainer">
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Icon iconName="search" />
            <SearchBox
              type="text"
              id="searchBox"
              placeholder={t("rvm.locationForm.mapSearchLocations")}
            />
          </div>
        </SearchBoxContainer>
      </div>
      <div
        id="bing-map"
        css={css`
          position: relative;
          width: 900px;
          height: 570px;
        `}
      />
      <div
        css={css`
          padding: 5px;
          margin: 5px 0px;
          text-align: right;
          position: absolute;
          right: 5px;
          bottom: 0;
          background: transparent;
          z-index: 101;
        `}
      >
        <PrimaryButton
          text={t("rvm.locationForm.mapSave")}
          css={css`
            margin-right: 5px;
          `}
          onClick={() => {
            let countryCode;
            if (loc.address?.countryRegionISO2) {
              const tax =
                taxonomy.Country.byCode[
                  `Country.${loc.address?.countryRegionISO2}`
                ];
              if (tax) {
                countryCode = {
                  label: t(`Country.${loc.address?.countryRegionISO2}`),
                  value: tax.id,
                };
              }
            }
            if (loc.address?.countryRegion) {
              let countryRegionCode;
              for (const [key, countryName] of Object.entries(countries)) {
                if (loc.address?.countryRegion === countryName) {
                  countryRegionCode = key;
                }
              }
              if (countryRegionCode !== undefined) {
                const tax =
                  taxonomy.Country.byCode[`Country.${countryRegionCode}`];
                if (tax) {
                  countryCode = {
                    label: t(tax.code),
                    value: tax.id,
                  };
                }
              }
            }

            // setFieldValue("locationName", loc.address?.formattedAddress);
            setFieldValue(
              "street",
              loc.address?.intersection?.baseStreet
                ? loc.address?.intersection?.baseStreet
                : loc.address?.addressLine
            );
            setFieldValue(
              "house",
              loc.address?.intersection?.baseStreet
                ? loc.address?.addressLine.substring(
                    loc.address?.intersection?.baseStreet.length + 1
                  )
                : ""
            );
            setFieldValue("countryCodeID", {
              label: loc.address?.countryRegion,
              value:
                taxonomy.Country.byCode[
                  `Country.${loc.address?.countryRegionIso2}`
                ].id,
            });
            //setFieldValue("buildingFloorDoor", loc.address?.formattedAddress);
            setFieldValue("zipCode", loc.address?.postalCode ?? "");
            setFieldValue("city", loc.address?.locality);
            setFieldValue("latitude", loc.lat);
            setFieldValue("longitude", loc.long);
            setFieldValue("formattedAddress", loc.address?.formattedAddress);
            // setFieldValue("countryCode", countryCode ?? null);
            //setInitValue({ ...initValue, ...(values as any) });
            closeModal();
          }}
          disabled={loc && !loc.address}
        />
        <DefaultButton
          text={t("rvm.locationForm.mapClose")}
          onClick={closeModal}
        />
      </div>
    </Modal>
  );
};

const SearchBoxContainer = styled.div`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const SearchBox = styled.input`
  width: 100%;
  padding: 5px;
  border: 0;
  outline: none;
`;
