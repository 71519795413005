import { Checkbox, PrimaryButton } from "@fluentui/react";
import { mergeStyleSets } from "@uifabric/merge-styles";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getCampaignInfo } from "../../../store/campaign/campaignSlice";
import { useSelector } from "../../../store/hooks";
import { AppDispatch } from "../../../store/store";
import {
  openAddLocationDialog,
  resetSearchForm,
  setAddress,
  setClientName,
  setClientNameForSingleClient,
  setCompanyCode,
  setDivision,
  setLocation,
  setLocationType,
  setShowActiveLocationsOnly,
} from "../../../store/valueMonitoring/valueMonitoringPage";
import FilterComboBoxField from "./FilterComboBoxField";

const FilterFields = () => {
  const loading =
    useSelector((s) => s.valueMonitoring.dataLoadStatus) === "loading";
  const data = useSelector((s) => s.valueMonitoring.data);

  const campaignInfo = useSelector((s) => s.campaign.campaignInfo);
  const isCampaignClosed = campaignInfo?.isCampaignClosed;

  const errors = useSelector((s) => s.valueMonitoring.errors);
  const userRole = useSelector((s) => s.userRole.userRole);
  const isInternalUser = userRole === "internal";
  const dispatch: AppDispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);

  const clientName = useSelector((s) => s.valueMonitoring.clientName);
  const clientNameOptions = useSelector(
    (s) => s.valueMonitoring.clientNameOptions
  );

  const division = useSelector((s) => s.valueMonitoring.division);
  const divisionOptions = useSelector((s) => s.valueMonitoring.divisionOptions);

  const locationType = useSelector((s) => s.valueMonitoring.locationType);
  const locationTypeOptions = useSelector(
    (s) => s.valueMonitoring.locationTypeOptions
  );

  let companyCode = useSelector((s) => s.valueMonitoring.companyCode);

  let address = useSelector((s) => s.valueMonitoring.address);

  const companyCodeOptions = useSelector(
    (s) => s.valueMonitoring.companyCodeOptions
  );

  const addressOptions = useSelector((s) => s.valueMonitoring.addressOptions);

  const location = useSelector((s) => s.valueMonitoring.location);
  const locationOptions = useSelector((s) => s.valueMonitoring.locationOptions);

  const showActiveLocationsOnly = useSelector(
    (s) => s.valueMonitoring.showActiveLocationsOnly
  );

  const clientData = useMemo(() => {
    return data?.find((el) => el.clientName === clientName?.label);
  }, [clientName, data]);

  const clientId = clientData?.clientId;

  const setClientNameMethod = (fieldName, value) => {
    dispatch(setClientName(value ? value.value : null));
  };

  const setLocationMethod = (fieldName, value) => {
    dispatch(setLocation({ item: value.label ? value.value : null }));
  };

  const setDivisionMethod = (fieldName, value) => {
    dispatch(setDivision(value ? value.value : null));
  };

  const setCompanyCodeMethod = (fieldName, value) => {
    dispatch(setCompanyCode(value ? value.value : null));
  };

  const setAddressMethod = (fieldName, value) => {
    dispatch(setAddress(value ? value.value : null));
  };

  const setLocationTypeMethod = (fieldName, value) => {
    dispatch(setLocationType(value ? value.value : null));
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (clientNameOptions && clientNameOptions.length === 1) {
      dispatch(setClientNameForSingleClient(clientNameOptions[0]));
    }
  }, [clientNameOptions]);

  const onGetCampaignInfo = useCallback(
    async (clientId) => {
      if (!clientId) return;
      try {
        dispatch(getCampaignInfo({ clientId }));
      } catch (error) {}
    },
    [clientId]
  );

  useEffect(() => {
    if (clientId) {
      onGetCampaignInfo(clientId);
    }
  }, [clientId]);

  const locationTypeOptionsPrepared = useMemo(() => {
    if (locationTypeOptions && taxonomy) {
      return locationTypeOptions.map((el) => {
        return {
          value: el.value,
          label: el.value
            ? t(taxonomy.LocationType.byId[el.value].code)
            : el.label,
        };
      });
    } else {
      return [];
    }
  }, [locationTypeOptions, taxonomy, t]);

  const locationTypePrepared = useMemo(() => {
    if (locationType && taxonomy) {
      return {
        value: locationType.value,
        label: locationType.value
          ? t(taxonomy.LocationType.byId[locationType.value].code)
          : locationType.value,
      };
    } else {
      return [];
    }
  }, [locationType, taxonomy, t]);

  const classes = mergeStyleSets({
    mainFields: {
      display: "flex",
      margin: "20px auto 0",
      // position: "sticky",
      // top: 44 ,
      // zIndex: 999,
      // background: theme.palette.white,
      padding: 0,
      maxWidth: "80%",
    },
    gridContainer: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: !isInternalUser ? "26% 26% 26% 18%" : "33% 33% 32%",
      gap: "5px 20px",
      padding: 0,
    },
    gridContainerFull: {
      boxSizing: "border-box",
      display: "grid",
      //gridTemplateColumns: "auto auto",
      gap: 20,
      padding: 0,
    },
    gridItem: {},
    gridItemCheckbox: {
      marginTop: "35px",
      marginLeft: "10px",
    },
    gridItemButton: {
      marginTop: "30px",
      marginLeft: "10px",
      textAlign: "right",
    },
    commentItem: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 1,
    },
    transparentBg: {
      background: "transparent",
    },
    locationContainer: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "80% 20%",
    },
    locationContainerWithoutButton: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "100% 0%",
    },
    buttonContainer: {
      marginTop: "30px",
      textAlign: "right",
    },
  });
  return (
    <>
      <div className={classes.mainFields}>
        <div className={classes.gridContainer}>
          <div className={classes.gridItem}>
            <FilterComboBoxField
              disabled={
                loading ||
                isInternalUser ||
                (clientNameOptions && clientNameOptions.length === 1)
              }
              errors={errors}
              name="clientName"
              label={t("rvm.clientName.label")}
              setFieldValue={setClientNameMethod}
              options={clientNameOptions || []}
              defaultValue={clientName}
              isMarked={false}
            />
          </div>
          <div className={classes.gridItem}>
            <FilterComboBoxField
              disabled={loading || isInternalUser || !clientName}
              errors={errors}
              name="division"
              label={t("rvm.division.label")}
              setFieldValue={setDivisionMethod}
              options={divisionOptions || []}
              defaultValue={division}
              isMarked={false}
            />
          </div>
          <div className={classes.gridItem}>
            <FilterComboBoxField
              disabled={loading || isInternalUser || !clientName}
              errors={errors}
              name="locationType"
              label={t("rvm.locationType.label")}
              setFieldValue={setLocationTypeMethod}
              options={locationTypeOptionsPrepared || []}
              defaultValue={locationTypePrepared}
              isMarked={false}
            />
          </div>
          {!isInternalUser ? (
            <div className={classes.gridItem}>
              <div className={classes.gridItemCheckbox}>
                <Checkbox
                  label={t("rvm.showPendingLocationsOnly.label")}
                  checked={showActiveLocationsOnly}
                  onChange={(e, checked) =>
                    dispatch(setShowActiveLocationsOnly(checked))
                  }
                  disabled={!clientName}
                />
              </div>
            </div>
          ) : null}
          <div className={classes.gridItem}>
            <FilterComboBoxField
              disabled={loading || isInternalUser || !clientName}
              errors={errors}
              name="address"
              label={t("rvm.address.label")}
              setFieldValue={setAddressMethod}
              options={addressOptions || []}
              defaultValue={address === null ? "" : address}
              isMarked={false}
            />
          </div>
          <div className={classes.gridItem}>
            <FilterComboBoxField
              disabled={loading || isInternalUser || !clientName}
              errors={errors}
              name="companyCode"
              label={t("rvm.companyCode.label")}
              setFieldValue={setCompanyCodeMethod}
              options={companyCodeOptions || []}
              defaultValue={companyCode === null ? "" : companyCode}
              isMarked={false}
            />
          </div>
          <div
            className={
              !isInternalUser
                ? classes.locationContainer
                : classes.locationContainerWithoutButton
            }
          >
            <div className={classes.gridItem}>
              <FilterComboBoxField
                disabled={loading || isInternalUser || !clientName}
                errors={errors}
                name="location"
                label={t("rvm.location.label")}
                setFieldValue={setLocationMethod}
                options={locationOptions || []}
                defaultValue={location}
                isMarked={false}
              />
            </div>
            {!isInternalUser ? (
              <div className={classes.buttonContainer}>
                <PrimaryButton
                  iconProps={{ iconName: "AddHome" }}
                  allowDisabledFocus
                  onClick={() => {
                    dispatch(openAddLocationDialog());
                  }}
                  disabled={
                    loading ||
                    isInternalUser ||
                    !clientName ||
                    location ||
                    isCampaignClosed
                  }
                  hidden={isInternalUser}
                  checked={false}
                />
              </div>
            ) : null}
          </div>
          {!isInternalUser ? (
            <div className={classes.gridContainerFull}>
              <div className={classes.gridItemButton}>
                <PrimaryButton
                  text={t("rvm.clearAllFilters.label")}
                  iconProps={{ iconName: "ClearFilter" }}
                  allowDisabledFocus
                  onClick={() => {
                    dispatch(resetSearchForm());
                  }}
                  disabled={false}
                  checked={false}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default FilterFields;
