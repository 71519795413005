import { getTheme, mergeStyleSets } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import "../../../ReadOnly.scss";
import { useSelector } from "../../../store/hooks";
import { formatNumber } from "../../../utils/number";
import { formatDateWithMoment } from "../../../utils/utils";
import ReadOnlyValuePair from "./ReadOnlyValuePair";
import { useMemo } from "react";

export const CurrentPropertyInfo = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = getTheme();

  const formObject = useSelector((s) => s.valueMonitoring.formObject);
  const clientInternalNumber = useSelector(
    (s) => s.valueMonitoring.selectedLocation?.client?.clientInternalNumber
  );
  const stockValuesExcludedClients =
    process.env.REACT_APP_EXCLUDE_STOCKVALUES_FIELD_CLIENTS;

  const isStockValuesFieldExcluded = useMemo(() => {
    const retVal = stockValuesExcludedClients
      ? stockValuesExcludedClients
          ?.split(",")
          .includes(clientInternalNumber + "")
      : true;

    return retVal;
  }, [clientInternalNumber, stockValuesExcludedClients]);

  const taxonomy = useSelector((s) => s.taxonomy);

  const generalStyles = mergeStyleSets({
    containerOne: {
      display: "grid",
      gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
      gridColumnGap: "20px",
      gridRowGap: "0px",
      fontSize: "14px",
      marginBottom: "130px",
    },
    valueTextWithIcon: {
      fontWeight: "600",
      display: "flex",
      alignItems: "flex-start",
      "span:nth-child(2)": {
        marginLeft: 4,
        cursor: "pointer",
        color: theme.palette.themeSecondary,
        textDecoration: "underline",
      },
    },

    sicCodes: {
      marginLeft: 4,
    },
  });
  const romClasses = mergeStyleSets({
    keyText: {
      marginRight: "6px",
      whiteSpace: "nowrap",
    },
    keyValueWrap: {
      marginBottom: "10px",
      display: "grid",
      gridTemplateColumns: "33% 66%",
      wordBreak: "break-word",
    },
    valueItem: {
      wordBreak: "break-word",
      fontWeight: "600",
    },
    flex: {
      display: "flex",
    },
  });

  return (
    <div className={generalStyles.containerOne}>
      <div className="gridItem">
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.statusDate.label"
          fieldName="statusDate"
        >
          {formObject && formObject?.statusDate
            ? formatDateWithMoment(formObject?.statusDate)
            : null}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.statusCode.label"
          fieldName="statusCode"
        >
          <>
            {formObject
              ? t(
                  taxonomy.PropertyInformationStatus.byId[
                    formObject?.statusCode
                  ]?.code
                )
              : null}
          </>
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.coveragePeriodFromDate.label"
          fieldName="coveragePeriodFromDate"
        >
          {formObject && formObject?.coveragePeriodFromDate
            ? formatDateWithMoment(formObject?.coveragePeriodFromDate)
            : null}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.coveragePeriodToDate.label"
          fieldName="coveragePeriodToDate"
        >
          {formObject && formObject?.coveragePeriodToDate
            ? formatDateWithMoment(formObject?.coveragePeriodToDate)
            : null}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.currencyCode.label"
          fieldName="currencyCode"
        >
          <>
            {formObject?.currencyCode
              ? t(taxonomy.Currency.byId[formObject?.currencyCode].code)
              : null}
          </>
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.buildingValues.label"
          fieldName="buildingValues"
        >
          {formObject ? formatNumber(formObject?.buildingValues) : null}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.equipmentValues.label"
          fieldName="equipmentValues"
        >
          {formObject ? formatNumber(formObject?.equipmentValues) : null}
        </ReadOnlyValuePair>
        {isStockValuesFieldExcluded ? null : (
          <ReadOnlyValuePair
            fieldsUpdatedByExternalUser={
              formObject?.fieldsUpdatedByExternalUser
            }
            label="rvm.stockValues.label"
            fieldName="stockValues"
          >
            {formObject ? formatNumber(formObject?.stockValues) : null}
          </ReadOnlyValuePair>
        )}
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.totalPd.label"
          fieldName="totalPd"
        >
          {formObject ? formatNumber(formObject?.totalPd) : null}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.businessInteruptionValues12Months.label"
          fieldName="businessInteruptionValues12Months"
        >
          {formObject
            ? formatNumber(formObject?.businessInteruptionValues12Months)
            : null}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.totalPdBi.label"
          fieldName="totalPdBi"
        >
          {formObject ? formatNumber(formObject?.totalPdBi) : null}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.indemnityPeriodCode.label"
          fieldName="indemnityPeriodCode"
        >
          <>
            {formObject
              ? formObject?.indemnityPeriodCode
                ? t(
                    taxonomy.IndemnityPeriod.byId[
                      formObject?.indemnityPeriodCode
                    ].code
                  )
                : null
              : null}
          </>
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.businessInteruptionValuesBasedOnIndemnityPeriod.label"
          fieldName="businessInteruptionValuesBasedOnIndemnityPeriod"
        >
          {formObject
            ? formatNumber(
                formObject?.businessInteruptionValuesBasedOnIndemnityPeriod
              )
            : null}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.totalPdBiBasedOnIndemnityPeriod.label"
          fieldName="totalPdBiBasedOnIndemnityPeriod"
        >
          {formObject
            ? formatNumber(formObject?.totalPdBiBasedOnIndemnityPeriod)
            : null}
        </ReadOnlyValuePair>
        {/* <ReadOnlyValuePair fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}label="rvm.clientComment.label">
          {formObject ? formObject?.clientComment : null}
        </ReadOnlyValuePair> */}

        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.locationOwningStatusCode.label"
          fieldName="desiredLocationOwningStatusCode"
        >
          <>
            {formObject
              ? t(
                  taxonomy.LocationOwnershipStatus.byId[
                    formObject?.desiredLocationOwningStatusCode
                  ]?.code
                )
              : null}
          </>
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.locationOwningStatusDate.label"
          fieldName="desiredLocationOwningStatusDate"
        >
          {formObject && formObject?.desiredLocationOwningStatusDate
            ? formatDateWithMoment(formObject?.desiredLocationOwningStatusDate)
            : null}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.locationStatusCode.label"
          fieldName="desiredLocationStatusCode"
        >
          <>
            {formObject
              ? t(
                  taxonomy.LocationStatus.byId[
                    formObject?.desiredLocationStatusCode
                  ]?.code
                )
              : null}
          </>
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          fieldsUpdatedByExternalUser={formObject?.fieldsUpdatedByExternalUser}
          label="rvm.locationStatusDate.label"
          fieldName="desiredLocationStatusDate"
        >
          {formObject && formObject?.desiredLocationStatusDate
            ? formatDateWithMoment(formObject?.desiredLocationStatusDate)
            : null}
        </ReadOnlyValuePair>
      </div>
    </div>
  );
};
