import { css } from "@emotion/css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
const LocationName = ({ row }) => {
  const taxonomy = useSelector((s) => s.taxonomy);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const linked =
    taxonomy?.PropertyInformationStatus.byId[row.location.statusCode].code !==
      "PropertyInformationStatus.NO_DATA" && row.propertyInformationId !== null;

  return linked ? (
    <div
      id={"location_" + row.locationId}
      className={css`
        cursor: pointer;
        text-decoration: underline;
        ${row.isBindToLocalLocation ? "color: #999999;" : null}
      `}
      onClick={() => {
        navigate(
          routes.valueMonitoringForLocation.getPath(
            row.clientId,
            row.locationId,
            row.propertyInformationId
          )
        );
      }}
    >
      {row.location.locationName}
    </div>
  ) : (
    <div id={"location_" + row.locationId}>{row.location.locationName}</div>
  );
};

export default LocationName;
