import { css } from "@emotion/css";
import { TooltipHost } from "@fluentui/react";
import { mergeStyleSets } from "@uifabric/merge-styles";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ListContextMenuIconButton,
  ListContextMenuIconButtonGrayed,
} from "../../../components/styled";
import { neutrals } from "../../../config/colors";
import { useSelector } from "../../../store/hooks";
import {
  setShowApproveLocationDialog,
  setShowInvitationForm,
  setShowLocationInfoDialog,
  setShowReminderForm,
} from "../../../store/locations/locations";
import { AppDispatch } from "../../../store/store";

type Props = {
  row: any;
};

const classes = mergeStyleSets({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "flex-tart",
    alignItems: "center",
  },
  emptyPlace: {
    width: "32px !important",
  },
});

const NotificationIconsCell = ({ row }: Props) => {
  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);
  const selection = useSelector((s) => s.locations.selection);
  const campaignInfo = useSelector((s) => s.campaign.campaignInfo);
  const campaignIsClosed = campaignInfo?.isCampaignClosed || !campaignInfo;

  const dispatch: AppDispatch = useDispatch();

  const canSendInvitation: boolean = useMemo(() => {
    return (
      (taxonomy?.PropertyInformationStatus.byId[row.location.statusCode]
        .code === "PropertyInformationStatus.DONE" ||
        taxonomy?.PropertyInformationStatus.byId[row.location.statusCode]
          .code === "PropertyInformationStatus.NO_DATA") &&
      !row.isBindToLocalLocation
    );
  }, [taxonomy, row.location.statusCode]);

  const canSendReminder: boolean = useMemo(() => {
    return (
      taxonomy?.PropertyInformationStatus.byId[row.location.statusCode].code ===
        "PropertyInformationStatus.INVITATION_SENT" &&
      !row.isBindToLocalLocation
    );
  }, [taxonomy, row.location.statusCode]);

  const isLocationLocal: boolean = useMemo(() => {
    return row.isBindToLocalLocation;
  }, [row.isBindToLocalLocation]);

  return (
    <div className={classes.container}>
      {selection.length <= 1 ? (
        <TooltipHost content={t("rvm.locationInfo.label")}>
          <ListContextMenuIconButton
            iconProps={{
              iconName: "Info",
            }}
            className={null}
            onClick={() => {
              dispatch(setShowLocationInfoDialog(true));
            }}
          />
        </TooltipHost>
      ) : (
        <ListContextMenuIconButtonGrayed
          iconProps={{
            iconName: "Info",
          }}
          className={greyedOut}
        />
      )}
      {canSendInvitation ? (
        selection.length <= 1 && !campaignIsClosed ? (
          <TooltipHost content={t("rvm.sendInvitation.label")}>
            <ListContextMenuIconButton
              iconProps={{
                iconName: "Send",
              }}
              className={null}
              onClick={() => {
                dispatch(setShowInvitationForm(true));
              }}
            />
          </TooltipHost>
        ) : (
          <ListContextMenuIconButtonGrayed
            iconProps={{
              iconName: "Send",
            }}
            className={greyedOut}
          />
        )
      ) : null}
      {canSendReminder ? (
        selection.length <= 1 ? (
          <TooltipHost content={t("rvm.sendReminder.label")}>
            <ListContextMenuIconButton
              iconProps={{
                iconName: "MailReminder",
              }}
              className={null}
              onClick={() => {
                dispatch(setShowReminderForm(true));
              }}
            />
          </TooltipHost>
        ) : (
          <ListContextMenuIconButtonGrayed
            iconProps={{
              iconName: "MailReminder",
            }}
            className={greyedOut}
          />
        )
      ) : null}
      {isLocationLocal ? (
        selection.length <= 1 ? (
          <TooltipHost content={t("rvm.approveLocation.label")}>
            <ListContextMenuIconButton
              iconProps={{
                iconName: "HomeVerify",
              }}
              className={null}
              onClick={() => {
                dispatch(setShowApproveLocationDialog(true));
              }}
            />
          </TooltipHost>
        ) : (
          <ListContextMenuIconButtonGrayed
            iconProps={{
              iconName: "HomeVerify",
            }}
            className={greyedOut}
          />
        )
      ) : null}
    </div>
  );
};

export default NotificationIconsCell;

const greyedOut = css`
  color: ${neutrals.neutralSecondary};
  &&&:hover {
    color: ${neutrals.neutralSecondary};
    border-color: ${neutrals.neutralSecondary};
  }
`;
