import { css as cssClass } from "@emotion/css";
import {
  DefaultButton,
  DialogFooter,
  mergeStyleSets,
  PrimaryButton,
} from "@fluentui/react";
import clsx from "clsx";
import { Formik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import * as yup from "yup";
import { toast } from "../../../components/FluentToast";
import { FormSpinner } from "../../../components/FormSpinner";
import { StyledPrimaryButton } from "../../../components/styled";
import { useSelector } from "../../../store/hooks";
import {
  generateReportActions,
  setExcelDialog,
  setExcelReportResponse,
} from "../../../store/locations/locations";
import { setYupLocale } from "../../../utils/setYupLocale";

const GenerateExcelReportFormDialog = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isReportLoading = useSelector(
    (s) => s.locations.generateReportLoadStatus === "loading"
  );
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const excelReportResponse = useSelector(
    (s) => s.locations.excelReportResponse
  );
  const clientId = useSelector((s) => s.clients.clientId);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(setExcelReportResponse(null));
    dispatch(setExcelDialog({ isOpen: false }));
  };

  const initialValues = {
    reportType: "coversheet",
  };

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup.object().shape({});
  }, [t]);
  const classes = getClassNames(theme);

  const onSubmit = (values) => {
    const payload = {
      reportType: values?.reportType,
      comment: null,
      insurerPartnerNumber: null,
      premiumType: null,
      validFromDate: null,
      validToDate: null,
      vehicleIds: null,
    };

    dispatch(
      generateReportActions.trigger({
        clientId,

        onSuccess: () => {
          toast.success(t("rvm.reportGeneratedSuccessfully.label"));
        },
      })
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({ submitForm }) => {
        return (
          <>
            {excelReportResponse ? (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                <PrimaryButton
                  title={excelReportResponse.name}
                  onClick={() => {
                    window.open(excelReportResponse.url);
                    dispatch(setExcelReportResponse(null));
                    close();
                  }}
                  text={t("rvm.downloadReport.label")}
                />
              </div>
            ) : (
              <>
                <span>{t("rvm.report.warning.label")}</span>
                <DialogFooter>
                  <SaveButton
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                  />
                  <DefaultButton onClick={close} text={t("rvm.close.label")} />
                </DialogFooter>
              </>
            )}
            {isReportLoading && <FormSpinner />}
          </>
        );
      }}
    </Formik>
  );
};

const SaveButton = ({
  onClick,
  isLoading,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <StyledPrimaryButton
      onClick={onClick}
      text={t("rvm.generateReport.label")}
      disabled={isDisabled}
      className={clsx(
        cssClass`
          margin-right: 5px;
        `,
        {
          isLoading,
        }
      )}
      iconProps={{
        iconName: isLoading ? "ProgressRingDots" : "",
      }}
    />
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    main: {},
    fieldItem: {
      width: "100%",
      marginTop: 20,
    },
    footer: {
      marginTop: 50,
    },
  });

export default GenerateExcelReportFormDialog;
