import { getTheme, mergeStyleSets } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import "../../../ReadOnly.scss";
import { useSelector } from "../../../store/hooks";
import { formatDateWithMoment } from "../../../utils/utils";
import ReadOnlyValuePair from "./ReadOnlyValuePair";

export const ApproveDialogInfo = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = getTheme();

  const locationsSelection = useSelector((s) => s.locations.selection);

  const formObject = useMemo(() => {
    return locationsSelection && locationsSelection.length > 0
      ? locationsSelection[0]
      : null;
  }, [locationsSelection]);

  const taxonomy = useSelector((s) => s.taxonomy);

  const generalStyles = mergeStyleSets({
    containerOne: {
      display: "grid",
      gridColumnGap: "20px",
      gridRowGap: "0px",
      fontSize: "14px",
    },
    valueTextWithIcon: {
      fontWeight: "600",
      display: "flex",
      alignItems: "flex-start",
      "span:nth-child(2)": {
        marginLeft: 4,
        cursor: "pointer",
        color: theme.palette.themeSecondary,
        textDecoration: "underline",
      },
    },

    sicCodes: {
      marginLeft: 4,
    },
  });

  let address = null;
  if (
    formObject?.location.addresses &&
    formObject?.location.addresses.length > 0
  ) {
    address = formObject?.location.addresses[0];
  }

  return (
    <div className={generalStyles.containerOne}>
      <div className="gridItem">
        <ReadOnlyValuePair
          label="rvm.locationName.label"
          fieldName="locationName"
        >
          {formObject?.location.locationName}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          label="rvm.companyCode.label"
          fieldName="companyCode"
        >
          {formObject?.location.companyCode}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          label="rvm.locationType.label"
          fieldName="locationType"
        >
          {
            t(
              taxonomy.LocationType.byId[formObject?.location.locationType]
                ?.code
            ) as any
          }
        </ReadOnlyValuePair>

        <ReadOnlyValuePair label="rvm.division.label" fieldName="divisionName">
          {formObject?.location.divisionName}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair label="rvm.sicCode.label" fieldName="sicCode">
          {t(taxonomy.SicCode.byId[formObject?.location.sicCode]?.code) as any}
        </ReadOnlyValuePair>

        <ReadOnlyValuePair
          label="rvm.currencyCode.label"
          fieldName="currencyCode"
        >
          <>
            {formObject?.location.currencyCode
              ? t(
                  taxonomy.Currency.byId[formObject?.location.currencyCode].code
                )
              : null}
          </>
        </ReadOnlyValuePair>

        <ReadOnlyValuePair
          label="rvm.locationOwningStatusCode.label"
          fieldName="locationOwningStatus"
        >
          {
            t(
              taxonomy.LocationOwnershipStatus.byId[
                formObject?.location.locationOwningStatus
              ]?.code
            ) as any
          }
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          label="rvm.locationOwningStatusDate.label"
          fieldName="locationOwningStatusDate"
        >
          {formObject?.location.locationOwningStatusDate
            ? formatDateWithMoment(
                formObject?.location.locationOwningStatusDate
              )
            : null}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          label="rvm.locationStatusCode.label"
          fieldName="locationStatus"
        >
          {
            t(
              taxonomy.LocationStatus.byId[formObject?.location.locationStatus]
                ?.code
            ) as any
          }
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          label="rvm.locationStatusDate.label"
          fieldName="locationStatusDate"
        >
          {formObject?.location.locationStatusDate
            ? formatDateWithMoment(formObject?.location.locationStatusDate)
            : null}
        </ReadOnlyValuePair>

        <ReadOnlyValuePair label="rvm.country.label" fieldName="country">
          {
            t(
              taxonomy.Country.byId[formObject?.location.countryCode]?.code
            ) as any
          }
        </ReadOnlyValuePair>

        <ReadOnlyValuePair label="rvm.street.label" fieldName="street">
          {address && address.street ? address.street : ""}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair
          label="rvm.stairFloorDoor.label"
          fieldName="stairFloorDoor"
        >
          {address && address.buildingFloorDoor
            ? address.buildingFloorDoor
            : ""}
        </ReadOnlyValuePair>
        <ReadOnlyValuePair label="rvm.city.label" fieldName="city">
          {address && address.city ? address.city : ""}
        </ReadOnlyValuePair>

        <ReadOnlyValuePair label="rvm.houseNo.label" fieldName="house">
          {address && address.house ? address.house : ""}
        </ReadOnlyValuePair>

        <ReadOnlyValuePair label="rvm.zip.label" fieldName="zipCode">
          {address && address.zipCode ? address.zipCode : ""}
        </ReadOnlyValuePair>
      </div>
    </div>
  );
};
