import { useTheme } from "@emotion/react";
import {
  ConstrainMode,
  DetailsListLayoutMode,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IRenderFunction,
  mergeStyleSets,
  ScrollablePane,
  SelectionMode,
  ShimmeredDetailsList,
  Sticky,
  StickyPositionType,
  TooltipHost,
} from "@fluentui/react";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ClientBasicTableInfo from "../../../components/ClientBasicTableInfo/ClientBasicTableInfo";
import { ResultsScore } from "../../../components/ResultsScore";
import { useSelector } from "../../../store/hooks";
import { setSortLocations } from "../../../store/locations/locations";
import { AppDispatch } from "../../../store/store";

import { defaultColumns } from "./columns";

const LocationsTable = ({ selection }) => {
  const locations = useSelector((s) => s.locations.filteredAndSortedLocations);
  const client = useSelector((s) => s.clients.client);
  const theme = useTheme();
  const { t } = useTranslation();
  const sort = useSelector((s) => s.locations.sort);
  const dispatch: AppDispatch = useDispatch();
  const isLoading =
    useSelector((s) => s.locations.dataLoadStatus) === "loading";
  const classes = getClassNames(theme);

  const onClickHeaderCell = (column) => {
    if (!column.isSortable) return;
    const isSorted = sort.key === column.key;
    if (isSorted) {
      if (sort.dir === "asc") {
        dispatch(
          setSortLocations({
            key: column.key,
            dir: "desc",
          })
        );
      }
      //
      else {
        dispatch(
          setSortLocations({
            key: "location.statusCode",
            dir: "asc",
          })
        );
      }
    }
    //
    else {
      dispatch(
        setSortLocations({
          key: column.key,
          dir: "asc",
        })
      );
    }
    selection.setAllSelected(false);
  };

  const detailsListColumns = useMemo(() => {
    const withTranslatedHeader = defaultColumns
      .map((column: any) => {
        let iconName = column.iconName;

        if (column.key === sort.key && column.isSortable) {
          iconName =
            sort.key === column.key
              ? sort!.dir === "asc"
                ? "SortUp"
                : "SortDown"
              : "Sort";
        }
        return {
          ...column,
          name: t(column.name),
          iconName: iconName,
        };
      })
      .concat([
        {
          key: "filler",
          label: "",
          width: 0,
        },
      ]);
    return [...withTranslatedHeader];
  }, [sort, t]);
  const onRenderColumnHeaderTooltip: IRenderFunction<
    IDetailsColumnRenderTooltipProps
  > = (tooltipHostProps) => {
    return (
      <TooltipHost
        {...tooltipHostProps}
        content={tooltipHostProps?.column?.name}
      />
    );
  };

  const onRenderDetailsHeader = (
    props: IDetailsHeaderProps | undefined,
    defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
  ) => (
    <Sticky
      stickyPosition={StickyPositionType.Header}
      isScrollSynced
      stickyClassName={classes.table}
    >
      {defaultRender!({
        ...props,
        onRenderColumnHeaderTooltip,
      })}
    </Sticky>
  );

  const _getKey = (item: any, index?: number): string => {
    return item && item?.locationId.toString();
  };

  let partnerData = useMemo(() => {
    let partnerDataVal = {
      partnerName: "",
      internalNumber: null,
      partnerId: null,
    };
    if (client) {
      partnerDataVal = {
        partnerName: client.clientName,
        internalNumber: client.clientInternalNumber,
        partnerId: client.clientId,
      };
    }
    return partnerDataVal;
  }, [client]);

  const pageClasses = mergeStyleSets({
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    resultsScore: {
      padding: "20px 20px 10px 30px",
    },
  });

  return (
    <>
      <div className={pageClasses.flexContainer}>
        <ClientBasicTableInfo
          clientType={"corporate"}
          clientName={partnerData?.partnerName}
          clientPartnerNumber={partnerData?.internalNumber}
          clientPartnerId={partnerData?.partnerId}
          hidePartnerNumberLabel
          style={{ container: { fontWeight: "600" } }}
          loading={partnerData === null}
        />
        <ResultsScore
          className={pageClasses.resultsScore}
          score={locations.length}
        />
      </div>
      <div className={classes.container} id="scrollableDiv">
        <ScrollablePane>
          <ShimmeredDetailsList
            items={locations || []}
            compact={false}
            columns={detailsListColumns || []}
            selectionMode={SelectionMode.multiple}
            selection={selection}
            setKey="single"
            getKey={_getKey}
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            enableShimmer={isLoading}
            shimmerLines={20}
            selectionPreservedOnEmptyClick={true}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox"
            constrainMode={ConstrainMode.unconstrained}
            onColumnHeaderClick={(ev, column) => {
              onClickHeaderCell(column);
            }}
            className={classes.table}
            onRenderDetailsHeader={(
              props: IDetailsHeaderProps | undefined,
              defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
            ) => onRenderDetailsHeader(props, defaultRender)}
          />
        </ScrollablePane>
      </div>
    </>
  );
};

const getClassNames = (theme) =>
  mergeStyleSets({
    container: {
      // height: "80vh",
      height: "calc(100vh - 143px)",
      marginBottom: "0px",
      marginLeft: "30px",
      position: "relative",
      overflow: "auto",
      width: "100%-30px",
    },

    table: {
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.isDark
          ? "rgb(2, 7, 10)"
          : theme.palette.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "30px !important",
      },

      ".ms-List-cell:hover, .ms-List-cell .is-selected": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "#eaeaea !important",
      },
      ".ms-List-cell .ms-DetailsRow:hover": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "#eaeaea !important",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "flex-start",
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "flex-start",
      },

      ".ms-DetailsHeader-cellName i": {
        marginRight: "5px",
        fontSize: "smaller",
        fontWeight: "400",
      },
    },
  });

export default LocationsTable;
