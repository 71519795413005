import { IObjectWithKey } from "@fluentui/react";
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import i18next from "i18next";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api/api";
import { toast } from "../../components/FluentToast";
import {
  ExcelReportResponse,
  IDocumentInfo,
  IMailConfiguration,
} from "../../types/types";
import { AppThunk, LoadStatus } from "../store";
import { createAsyncRoutine, handleAxiosError } from "../util";

export type LocationsPageState = {
  locations: any[];
  selection: any[];
  searchFilter: string;
  filteredAndSortedLocations: any[];
  sort: any;
  dataLoadStatus: LoadStatus;
  generateReportLoadStatus: LoadStatus;
  showInvitationForm: boolean;
  mailConfiguration: IMailConfiguration;
  documentConfiguration: IDocumentInfo[];
  showReminderForm: boolean;
  showApproveLocationDialog: boolean;
  showLocationInfoDialog: boolean;
  excelReportResponse: ExcelReportResponse;
  deleteDialog: {
    clientId: number;
    locationId: number;
    rowVersion: string;
    isOpen: boolean;
    isLoading: boolean;
  };
  approveLocationDialog: {
    locationId: number;
    isOpen: boolean;
    isLoading: boolean;
  };
  excelDialog: {
    isOpen: boolean;
    icon?: string;
    title: string;
    color: string;
    data?: {
      [key: string]: any;
    };
  };
};

export const addPropertyInfoActionsRoutine = createAsyncRoutine<
  {
    formObject: any;

    onSuccess?: () => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any,
  any
>("locationsPage/addPropertyInfo");

export const sendReminderRoutine = createAsyncRoutine<
  {
    formObject: any;

    onSuccess?: () => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any,
  any
>("locationsPage/sendReminder");

export const generateReportActions = createAsyncRoutine<
  {
    clientId: any;
    // body: API.ExcelReportRequestBody;
    onSuccess?: () => void;
    onError?: () => void;
  },
  void,
  ExcelReportResponse,
  any
>("locationsPage/generateExcelReport");

export const deleteLocationRoutine = createAsyncRoutine<
  {
    clientId: number;
    locationId: number;
    comment: string;
    rowVersion: string;
    onSuccess?: () => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any,
  any
>("locationsPage/deleteLocation");

export const approveLocationRoutine = createAsyncRoutine<
  {
    clientId: number;
    locationId: number;
    onSuccess?: () => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any,
  any
>("locationsPage/approveLocation");

export const applyFiltersLocations = (): AppThunk => (dispatch, getState) => {
  const locations = getState().locations.locations;
  const search = getState().locations.searchFilter.trim();
  const sortState = getState().locations.sort;

  const filteredLocations = locations.filter((l) => {
    let isSearch = true;
    if (search !== "") {
      isSearch =
        l.locationName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    }
    let isFilters = true;
    return isSearch && isFilters;
  });

  const shownLocations = filteredLocations.slice(0).sort(sort(sortState));

  dispatch(setFilteredAndSortedLocations(shownLocations));
};

const initialState: LocationsPageState = {
  locations: [],
  selection: [],
  mailConfiguration: null,
  documentConfiguration: [],
  dataLoadStatus: "none",
  generateReportLoadStatus: "none",
  filteredAndSortedLocations: [],
  excelReportResponse: null,
  searchFilter: "",
  sort: {
    key: "locationName",
    dir: "asc",
  },
  showInvitationForm: false,
  showReminderForm: false,
  showApproveLocationDialog: false,
  showLocationInfoDialog: false,
  deleteDialog: {
    clientId: null,
    locationId: null,
    rowVersion: null,
    isOpen: false,
    isLoading: false,
  },
  approveLocationDialog: {
    locationId: null,
    isOpen: false,
    isLoading: false,
  },
  excelDialog: {
    isOpen: false,
    icon: "",
    title: "",
    color: "",
  },
};

export const locationsPageSlice = createSlice({
  name: "locationsPage",
  initialState,
  reducers: {
    resetState: (s) => {
      Object.keys(s).forEach((key) => {
        s[key] = initialState[key];
      });
    },
    setDeleteDialog: (
      s,
      a: PayloadAction<LocationsPageState["deleteDialog"]>
    ) => {
      s.deleteDialog = a.payload;
    },
    setApproveLocationDialog: (
      s,
      a: PayloadAction<LocationsPageState["approveLocationDialog"]>
    ) => {
      s.approveLocationDialog = a.payload;
    },
    setExcelReportResponse: (
      s,
      a: PayloadAction<LocationsPageState["excelReportResponse"]>
    ) => {
      s.excelReportResponse = a.payload;
    },
    setFilteredAndSortedLocations: (
      s,
      a: PayloadAction<LocationsPageState["filteredAndSortedLocations"]>
    ) => {
      s.filteredAndSortedLocations = a.payload;
    },
    setLocations: (s, a: PayloadAction<LocationsPageState["locations"]>) => {
      s.locations = a.payload;
    },
    setSelection: (s, a: PayloadAction<IObjectWithKey[]>) => {
      s.selection = a.payload;
    },
    setSearchFilter: (
      s,
      a: PayloadAction<LocationsPageState["searchFilter"]>
    ) => {
      s.searchFilter = a.payload;
      // const locations = s.locations;
      // const search = s.searchFilter.trim();
      // const sortState = s.sort;
      const filteredLocations = current(s.locations).filter((l) => {
        const searchFilter = s.searchFilter.trim();
        let isSearch = true;
        if (searchFilter !== "") {
          isSearch =
            l.location.locationName
              .toLowerCase()
              .indexOf(searchFilter.toLowerCase()) !== -1;
        }
        let isFilters = true;
        return isSearch && isFilters;
      });
      s.filteredAndSortedLocations = filteredLocations
        .slice(0)
        .sort(sort(s.sort));
    },
    setSort: (s, a: PayloadAction<LocationsPageState["sort"]>) => {
      s.sort = a.payload;
      const filteredLocations = current(s.locations).filter((l) => {
        const searchFilter = s.searchFilter.trim();
        let isSearch = true;
        if (searchFilter !== "") {
          isSearch =
            l.location.locationName
              .toLowerCase()
              .indexOf(searchFilter.toLowerCase()) !== -1;
        }
        let isFilters = true;
        return isSearch && isFilters;
      });
      s.filteredAndSortedLocations = filteredLocations
        .slice(0)
        .sort(sort(s.sort));
    },
    setShowInvitationForm: (
      s,
      a: PayloadAction<LocationsPageState["showInvitationForm"]>
    ) => {
      s.showInvitationForm = a.payload;
    },
    setMailConfiguration: (
      s,
      a: PayloadAction<LocationsPageState["mailConfiguration"]>
    ) => {
      s.mailConfiguration = a.payload;
    },
    setDocumentConfiguration: (
      s,
      a: PayloadAction<LocationsPageState["documentConfiguration"]>
    ) => {
      s.documentConfiguration = a.payload;
    },
    setShowReminderForm: (
      s,
      a: PayloadAction<LocationsPageState["showReminderForm"]>
    ) => {
      s.showReminderForm = a.payload;
    },
    setShowApproveLocationDialog: (
      s,
      a: PayloadAction<LocationsPageState["showApproveLocationDialog"]>
    ) => {
      s.showApproveLocationDialog = a.payload;
    },
    setShowLocationInfoDialog: (
      s,
      a: PayloadAction<LocationsPageState["showLocationInfoDialog"]>
    ) => {
      s.showLocationInfoDialog = a.payload;
    },
    hideDeleteLocationDialog: (s, a: any) => {
      s.deleteDialog = { ...initialState.deleteDialog };
    },
    setExcelDialog: (
      s,
      a: PayloadAction<Partial<LocationsPageState["excelDialog"]>>
    ) => {
      s.excelDialog = { ...s.excelDialog, ...a.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadLocationsRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(loadLocationsRoutine.success, (s, a) => {
      s.locations = a.payload; //.filter((e) => e.statusCode !== 1);
      s.filteredAndSortedLocations = a.payload;
      s.dataLoadStatus = "success";
    });
    builder.addCase(loadLocationsRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });
    builder.addCase(loadLocationsForClientRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(loadLocationsForClientRoutine.success, (s, a) => {
      s.locations = a.payload; //.filter((e) => e.statusCode !== 1);
      s.filteredAndSortedLocations = a.payload;
      s.selection = [];
      s.dataLoadStatus = "success";
    });
    builder.addCase(loadLocationsForClientRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(addPropertyInfoActionsRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(addPropertyInfoActionsRoutine.success, (s, a) => {
      s.selection = [];
      s.searchFilter = "";
      // const updatedValues = a.payload;
      // const updatedValuesLocationIds = updatedValues.map((el) => el.locationId);
      // const locationsWithoutUpdatedValues = s.locations.map((el) => {
      //   if (updatedValuesLocationIds.includes(el.locationId)) {
      //     return {
      //       ...el,
      //       ...updatedValues.find(
      //         (updatedValue) => updatedValue.locationId === el.locationId
      //       ),
      //     };
      //   } else {
      //     return { ...el };
      //   }
      // });
      // s.selection = updatedValues;
      // s.locations = locationsWithoutUpdatedValues;
      s.dataLoadStatus = "success";
    });
    builder.addCase(addPropertyInfoActionsRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(sendReminderRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(sendReminderRoutine.success, (s, a) => {
      const updatedValues = a.payload;
      const updatedValuesLocationIds = updatedValues.map((el) => el.locationId);
      const locationsWithoutUpdatedValues = s.locations.map((el) => {
        if (updatedValuesLocationIds.includes(el.locationId)) {
          return {
            ...el,
            ...updatedValues.find(
              (updatedValue) => updatedValue.locationId === el.locationId
            ),
          };
        } else {
          return { ...el };
        }
      });
      s.selection = updatedValues;
      s.locations = locationsWithoutUpdatedValues;
      s.dataLoadStatus = "success";
    });
    builder.addCase(sendReminderRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(deleteLocationRoutine.loading, (s, a) => {
      s.deleteDialog.isLoading = true;
    });
    builder.addCase(deleteLocationRoutine.success, (s, a) => {
      s.deleteDialog = { ...initialState.deleteDialog };
    });
    builder.addCase(deleteLocationRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(approveLocationRoutine.loading, (s, a) => {
      s.deleteDialog.isLoading = true;
    });
    builder.addCase(approveLocationRoutine.success, (s, a) => {
      s.approveLocationDialog = { ...initialState.approveLocationDialog };
    });
    builder.addCase(approveLocationRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(generateReportActions.loading, (s) => {
      s.generateReportLoadStatus = "loading";
    });
    builder.addCase(generateReportActions.success, (s, a) => {
      s.generateReportLoadStatus = "success";
      s.excelReportResponse = a.payload;
    });
    builder.addCase(generateReportActions.error, (s) => {
      s.generateReportLoadStatus = "error";
    });
  },
});

export const sort = (sort: any) => (a: any, b: any) => {
  let aValue = a[sort.key];
  let bValue = b[sort.key];
  if (sort.key.indexOf(".") !== -1) {
    aValue = a[sort.key.split(".")[0]][sort.key.split(".")[1]];
    bValue = b[sort.key.split(".")[0]][sort.key.split(".")[1]];
  }

  if (aValue === null) aValue = "";
  if (bValue === null) bValue = "";

  if (aValue > bValue) {
    if (sort.dir === "desc") return -1;
    else return 1;
  }
  //
  else if (aValue < bValue) {
    if (sort.dir === "desc") return 1;
    else return -1;
  }
  //
  else {
    return 0;
  }
};

export const loadLocationsRoutine = createAsyncRoutine<void, void, any[], any>(
  "locationsPage/loadLocations"
);

export const loadLocationsForClientRoutine = createAsyncRoutine<
  { clientId: string },
  void,
  any[],
  any
>("locationsPage/loadLocationsForClient");

export const searchSortLocationsActions = createAsyncRoutine<
  {
    isReset: boolean;
  },
  void,
  void
>("locationsPage/searchSortLocations");

export const {
  actions: {
    setLocations,
    setSelection,
    setShowInvitationForm,
    setShowReminderForm,
    setFilteredAndSortedLocations,
    setSort: setSortLocations,
    setSearchFilter: setSearch,
    resetState,
    setDeleteDialog,
    hideDeleteLocationDialog,
    setApproveLocationDialog,
    setShowApproveLocationDialog,
    setShowLocationInfoDialog,
    setExcelDialog,
    setExcelReportResponse,
    setMailConfiguration,
    setDocumentConfiguration,
  },
  reducer: locationsReducer,
} = locationsPageSlice;

function* loadLocationsSaga() {
  yield takeEvery(
    loadLocationsRoutine.trigger,
    function* (a: ReturnType<typeof loadLocationsRoutine.trigger>) {
      try {
        yield put(loadLocationsRoutine.loading());
        const res = yield call(API.getPropertyInfos);
        yield put(loadLocationsRoutine.success(res.data));
      } catch (err) {
        yield put(loadLocationsRoutine.error(err));
        handleAxiosError(err);
      }
    }
  );
}

export function* generateReportSaga() {
  yield takeEvery(
    generateReportActions.trigger,
    function* (a: ReturnType<typeof generateReportActions.trigger>) {
      const { clientId, onSuccess, onError } = a.payload;
      try {
        yield put(generateReportActions.loading());
        const res = yield call(API.generateClientReportDocument, {
          clientId: clientId,
        });
        yield put(generateReportActions.success(res.data));
        //toast.success(i18next.t("bfm.success"));
        onSuccess && onSuccess();
      } catch (err) {
        yield put(generateReportActions.error(err));
        handleAxiosError(err);
        onError && onError();
      }
    }
  );
}

function* loadLocationsForClientSaga() {
  yield takeEvery(
    loadLocationsForClientRoutine.trigger,
    function* (a: ReturnType<typeof loadLocationsForClientRoutine.trigger>) {
      // const { clientId } = a.payload;
      try {
        yield put(loadLocationsForClientRoutine.loading());
        const res = yield call(API.getPropertyInfosForClient, a.payload);
        yield put(loadLocationsForClientRoutine.success(res.data));
      } catch (err) {
        yield put(loadLocationsForClientRoutine.error(err));
        handleAxiosError(err);
      }
    }
  );
}

function* addPropertyInfoSaga() {
  yield takeEvery(
    addPropertyInfoActionsRoutine.trigger.type,
    function* (a: ReturnType<typeof addPropertyInfoActionsRoutine.trigger>) {
      const { onSuccess, onError } = a.payload;
      try {
        yield put(addPropertyInfoActionsRoutine.loading());
        const res = yield call(API.addPropertyInfo, a.payload);
        yield put(addPropertyInfoActionsRoutine.success(res.data));
        toast.success(i18next.t("rvm.success"));
        onSuccess && onSuccess();
      } catch (err: any) {
        yield put(addPropertyInfoActionsRoutine.error(err));
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

function* sendReminderSaga() {
  yield takeEvery(
    sendReminderRoutine.trigger.type,
    function* (a: ReturnType<typeof sendReminderRoutine.trigger>) {
      const { onSuccess, onError } = a.payload;
      try {
        yield put(sendReminderRoutine.loading());
        const res = yield call(API.sendReminder, a.payload);
        yield put(sendReminderRoutine.success(res.data));
        toast.success(i18next.t("rvm.success"));
        onSuccess && onSuccess();
      } catch (err: any) {
        yield put(sendReminderRoutine.error(err));
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

function* deleteLocationSaga() {
  yield takeEvery(
    deleteLocationRoutine.trigger.type,
    function* (a: ReturnType<typeof deleteLocationRoutine.trigger>) {
      const { clientId, locationId, comment, rowVersion, onSuccess, onError } =
        a.payload;
      try {
        yield put(deleteLocationRoutine.loading());
        const res = yield call(API.deleteLocation, {
          clientId,
          locationId,
          comment,
          rowVersion,
        });
        yield put(deleteLocationRoutine.success(res.data));
        toast.success(i18next.t("rvm.success"));
        onSuccess && onSuccess();
      } catch (err: any) {
        yield put(deleteLocationRoutine.error(err));
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

function* approveLocationSaga() {
  yield takeEvery(
    approveLocationRoutine.trigger.type,
    function* (a: ReturnType<typeof approveLocationRoutine.trigger>) {
      const { clientId, locationId, onSuccess, onError } = a.payload;
      try {
        yield put(approveLocationRoutine.loading());
        const res = yield call(API.approveLocation, {
          clientId,
          locationId,
        });
        yield put(approveLocationRoutine.success(res.data));
        toast.success(i18next.t("rvm.success"));
        onSuccess && onSuccess();
      } catch (err: any) {
        yield put(approveLocationRoutine.error(err));
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

// function* sortLocationsSaga() {
//   yield takeEvery(setSortLocations.type, function* () {
//     yield put(applyFiltersLocations() as any);
//   });
// }

function* setSortSaga() {
  yield takeEvery(setSortLocations.type, function* () {
    yield put(
      searchSortLocationsActions.trigger({
        isReset: true,
      })
    );
  });
}

// function* searchFilterSaga() {
//   yield takeEvery(setSearch.type, function* () {
//     yield put(applyFiltersLocations() as any);
//   });
// }

function* setSearchSaga() {
  yield takeEvery(setSearch.type, function* () {
    yield put(
      searchSortLocationsActions.trigger({
        isReset: true,
      })
    );
  });
}

export function* locationsSaga() {
  // yield fork(sortLocationsSaga);
  yield fork(setSortSaga);
  yield fork(deleteLocationSaga);
  yield fork(setSearchSaga);
  yield fork(loadLocationsSaga);
  yield fork(loadLocationsForClientSaga);
  yield fork(addPropertyInfoSaga);
  yield fork(sendReminderSaga);
  yield fork(approveLocationSaga);
  yield fork(generateReportSaga);
}
