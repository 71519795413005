import styled from "@emotion/styled";
import { Icon } from "@fluentui/react";
import { useTranslation } from "react-i18next";

type Props = {
  score: number;
  className?: string;
};

export const ResultsScore = ({ score, className }: Props) => {
  const { t } = useTranslation();
  return (
    <Container className={className}>
      <StyledIcon iconName="CircleFill" />
      <ResultsLabel>{t("greco.searchResults")}</ResultsLabel>
      <ResultNumber>{score}</ResultNumber>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ResultsLabel = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  margin-right: 5px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const ResultNumber = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const StyledIcon = styled(Icon)`
  font-size: 8px;
  color: ${(p) => p.theme.palette.themePrimary};
  margin-right: 8px;
`;
