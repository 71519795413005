import { css as cssClass } from "@emotion/css";
import {
  Dialog,
  DialogFooter,
  DialogType,
  DirectionalHint,
  Icon,
  IconButton,
  TooltipHost,
  mergeStyleSets,
} from "@fluentui/react";
import { formatISO } from "date-fns";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { FormSpinner } from "../../../components/FormSpinner";
import ComboBoxField from "../../../components/controls/ComboBoxField";
import CreatableComboBoxField from "../../../components/controls/CreatableComboBoxField";
import DatePickerField from "../../../components/controls/DatePickerField";
import DialogButton from "../../../components/controls/DialogButton";
import TextInputField from "../../../components/controls/TextInputField";
import { useSelector } from "../../../store/hooks";
import {
  addLocationRoutine,
  loadPropertyInfoRoutine,
  setLocation,
} from "../../../store/valueMonitoring/valueMonitoringPage";
import { setYupLocale } from "../../../utils/setYupLocale";
import FilterComboBoxField from "../form/FilterComboBoxField";
import { MapModal } from "./MapModal";

type Props = {
  close: () => void;
  values?: any;
};

const AddLocationDialog = ({ close }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoading =
    useSelector((s) => s.valueMonitoring.dataLoadStatus) === "loading";
  const taxonomy = useSelector((s) => s.taxonomy);

  const clientName = useSelector((s) => s.valueMonitoring.clientName);

  const divisionOptions = useSelector((s) => s.valueMonitoring.divisionOptions);

  const data = useSelector((s) => s.valueMonitoring.data);

  const clientData = useMemo(() => {
    return data?.find((el) => el.clientName === clientName.label);
  }, [clientName, data]);

  const clientId = clientData.clientId;

  const clientCountryCode = clientData?.location?.countryCode;

  const clientCurrencyCode = clientData?.currencyCode;

  const clientNameOptions = useSelector(
    (s) => s.valueMonitoring.clientNameOptions
  );

  const [didTrySubmit, setDidTrySubmit] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [initValue, setInitValue] = useState({
    coveragePeriodFromDate: null,
    coveragePeriodToDate: null,

    locationName: "",
    currencyCode: null,

    sicCode: null,
    companyCode: "",
    divisionName: null,
    locationType: null,

    locationStatus: null,
    locationStatusDate: null,
    locationOwningStatus: null,
    locationOwningStatusDate: null,

    street: "",
    countryCodeID: null,

    house: "",
    buildingFloorDoor: "",
    zipCode: "",
    city: "",
    latitude: null,
    longitude: null,
    formattedAddress: "",
  });

  const currencyCodeOptions = useMemo(() => {
    return taxonomy.Currency.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy]);

  const sicCodeOptions = useMemo(() => {
    return taxonomy.SicCode.items
      .map((item) => ({
        value: item.id,
        label: t(item.code),
      }))
      .sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
      });
  }, [t, taxonomy]);

  const countryOptions = useMemo(() => {
    return taxonomy.Country.items
      .map((item) => ({
        value: item.id,
        label: t(item.code),
      }))
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
  }, [t, taxonomy]);

  const locationOwningStatusCodeOptions = useMemo(() => {
    return taxonomy.LocationOwnershipStatus.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy]);

  const locationStatusCodeOptions = useMemo(() => {
    return taxonomy.LocationStatus.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy]);

  const locationTypeCodeOptions = useMemo(() => {
    return taxonomy.LocationType.items.map((item) => ({
      value: item.id,
      label: t(item.code),
    }));
  }, [t, taxonomy]);

  const onSubmit = async (data) => {
    const formObject = {
      coveragePeriodFromDate: data.coveragePeriodFromDate,
      coveragePeriodToDate: data.coveragePeriodToDate,

      locationName: data.locationName,
      locationOwningStatus: data.locationOwningStatus.value,
      locationStatus: data.locationStatus.value,
      locationType: data.locationType.value,
      companyCode: data.companyCode,
      divisionName: data.divisionName?.value,

      countryCode: data.countryCodeID.value,
      currencyCode: data.currencyCode.value,
      sicCode: data.sicCode.value,
      locationStatusDate: formatISO(data.locationStatusDate, {
        representation: "date",
      }),
      locationOwningStatusDate: formatISO(data.locationOwningStatusDate, {
        representation: "date",
      }),

      addresses: [
        {
          positionLatitude: data.latitude,
          positionLongitude: data.longitude,
          buildingFloorDoor: data.buildingFloorDoor,
          city: data.city,
          countryCodeID: data.countryCodeID.value,
          house: data.house,
          street: data.street,
          zipCode: data.zipCode,
        },
      ],
    };

    await dispatch(
      addLocationRoutine.trigger({
        clientId,
        formObject,
        onSuccess: (location) => {
          dispatch(
            loadPropertyInfoRoutine.trigger({
              clientId,
              onSuccess: () => {
                dispatch(
                  setLocation({
                    item: {
                      value: location.locationId,
                      label: location.locationName,
                    },
                    resetFilters: true,
                  })
                );
              },
            })
          );
        },
      })
    );

    close();
  };

  const validationSchema = useMemo(() => {
    const schemaObj = {
      locationName: yup.string().required(),

      locationStatus: yup.mixed().required(),
      locationOwningStatus: yup.mixed().required(),
      locationType: yup.mixed().required(),
      currencyCode: yup.mixed().required(),
      sicCode: yup.mixed().required(),
      locationStatusDate: yup.date().required(),
      locationOwningStatusDate: yup.date().required(),
      street: yup.string().required(),
      countryCodeID: yup.mixed().required(),

      zipCode: yup.string().required(),
      city: yup.string().required(),
    };
    return yup.object().shape(schemaObj);
  }, []);

  useEffect(() => {
    if (currencyCodeOptions && clientCurrencyCode) {
      setInitValue((prev) => ({
        ...prev,
        currencyCode: currencyCodeOptions.find(
          (item) => item.value.toString() === clientCurrencyCode.toString()
        ),
      }));
    }

    if (countryOptions.length && clientCountryCode) {
      setInitValue((prev) => ({
        ...prev,
        countryCodeID: countryOptions.find(
          (item) => item.value.toString() === clientCountryCode.toString()
        ),
      }));
    }
  }, [
    countryOptions,
    clientCountryCode,
    clientCurrencyCode,
    currencyCodeOptions,
  ]);

  useEffect(() => {
    setYupLocale(t);
  }, [t]);

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("rvm.addLocation.label")}
          </div>
        ),
        className: cssClass`
        .ms-Dialog-title {
          margin: 20px;
          padding-bottom: 10px;
        }
        .ms-Dialog-inner {
          margin: 20px;
          padding-bottom: 14px;
        }
        .ms-Button {
          padding: 10px 35px;
          //background: rgb(0, 184, 238);
        }
        .select__menu {
        }
      `,
        showCloseButton: true,
      }}
      minWidth={1100}
      modalProps={{
        isBlocking: true, //phraseActivated ? false :
      }}
    >
      <Formik
        initialValues={initValue}
        onSubmit={onSubmit}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {({ submitForm, errors, setFieldValue, values, handleChange }) => {
          return (
            <>
              <div className={classes.mainFields}>
                <div className={classes.gridContainer}>
                  <div className={classes.gridItem2}>
                    <FilterComboBoxField
                      disabled={true}
                      errors={errors}
                      name="clientName"
                      label={t("rvm.clientName.label")}
                      setFieldValue={null}
                      options={clientNameOptions || []}
                      defaultValue={clientName}
                      required
                    />
                  </div>

                  <div className={classes.gridItem3}>
                    <TextInputField
                      setFieldValue={setFieldValue}
                      value={values.locationName}
                      onChange={handleChange}
                      errors={errors}
                      name={"locationName"}
                      disabled={false}
                      label={t("rvm.locationName.label")}
                      required
                    />
                  </div>
                  <div className={classes.gridItem}>
                    <ComboBoxField
                      disabled={false}
                      errors={errors}
                      name="currencyCode"
                      label={"rvm.currencyCode.label"}
                      setFieldValue={setFieldValue}
                      options={currencyCodeOptions || []}
                      defaultValue={values.currencyCode}
                      required
                    />
                  </div>
                  <div className={classes.gridItem2}>
                    <div className={classes.mapFields}>
                      <div className={classes.mapAddress}>
                        <CreatableComboBoxField
                          disabled={false}
                          errors={errors}
                          name="divisionName"
                          label={t("rvm.division.label")}
                          setFieldValue={setFieldValue}
                          options={divisionOptions || []}
                          defaultValue={
                            values.divisionName !== null
                              ? values.divisionName
                              : null
                          }
                        />
                      </div>
                      <TooltipHost
                        content={t("rvm.divisionNameInfo.label")}
                        directionalHint={DirectionalHint.rightCenter}
                        hidden={false}
                      >
                        <Icon iconName={"Info"} className={classes.iconInfo} />
                      </TooltipHost>
                    </div>
                  </div>
                  <div className={classes.gridItem}>
                    <ComboBoxField
                      disabled={false}
                      errors={errors}
                      name="sicCode"
                      label={"rvm.sicCode.label"}
                      setFieldValue={setFieldValue}
                      options={sicCodeOptions || []}
                      defaultValue={values.sicCode}
                      required
                    />
                  </div>

                  <div className={classes.gridItem}>
                    <ComboBoxField
                      disabled={false}
                      errors={errors}
                      name="locationType"
                      label={t("rvm.locationType.label")}
                      setFieldValue={setFieldValue}
                      options={locationTypeCodeOptions || []}
                      defaultValue={
                        values.locationType !== null
                          ? values.locationType
                          : null
                      }
                      required
                    />
                  </div>
                  <div className={classes.gridItem}>
                    <ComboBoxField
                      disabled={false}
                      errors={errors}
                      name="locationStatus"
                      label={"rvm.locationStatusCode.label"}
                      setFieldValue={setFieldValue}
                      options={locationStatusCodeOptions || []}
                      defaultValue={
                        values.locationStatus !== null
                          ? values.locationStatus
                          : null
                      }
                      required
                    />
                  </div>
                  <div className={classes.gridItem}>
                    <DatePickerField
                      required
                      errors={errors}
                      setFieldValue={setFieldValue}
                      defaultValue={
                        values.locationStatusDate
                          ? values.locationStatusDate
                          : ""
                      }
                      name={"locationStatusDate"}
                      disabled={false}
                      label={"rvm.locationStatusDate.label"}
                      placeholder={t("rvm.selectDate")}
                    />
                  </div>
                  <div className={classes.gridItem}>
                    <ComboBoxField
                      disabled={false}
                      errors={errors}
                      name="locationOwningStatus"
                      label={"rvm.locationOwningStatusCode.label"}
                      setFieldValue={setFieldValue}
                      options={locationOwningStatusCodeOptions || []}
                      defaultValue={
                        values.locationOwningStatus !== null
                          ? values.locationOwningStatus
                          : null
                      }
                      required
                    />
                  </div>
                  <div className={classes.gridItem}>
                    <DatePickerField
                      required
                      errors={errors}
                      setFieldValue={setFieldValue}
                      defaultValue={
                        values.locationOwningStatusDate
                          ? values.locationOwningStatusDate
                          : ""
                      }
                      name={"locationOwningStatusDate"}
                      disabled={false}
                      label={"rvm.locationOwningStatusDate.label"}
                      placeholder={t("rvm.selectDate")}
                    />
                  </div>
                  <div className={classes.gridItem3}>
                    <div className={classes.mapFields}>
                      <div className={classes.mapAddress}>
                        <TextInputField
                          setFieldValue={setFieldValue}
                          value={values.street}
                          onChange={handleChange}
                          errors={errors}
                          name={"street"}
                          disabled={false}
                          label={t("rvm.street.label")}
                          required
                        />
                      </div>
                      <IconButton
                        className={classes.noPadding}
                        iconProps={{ iconName: "MapPin" }}
                        onClick={() => {
                          setIsModalOpen(true);
                        }}
                      />
                    </div>
                  </div>
                  <div className={classes.gridItem}>
                    <ComboBoxField
                      disabled={false}
                      errors={errors}
                      name="countryCodeID"
                      label={t("rvm.country.label")}
                      setFieldValue={setFieldValue}
                      options={countryOptions || []}
                      defaultValue={values.countryCodeID}
                      required
                    />
                  </div>
                  <div className={classes.gridItem}>
                    <TextInputField
                      setFieldValue={setFieldValue}
                      value={values.house}
                      onChange={handleChange}
                      errors={errors}
                      name={"house"}
                      disabled={false}
                      label={t("rvm.houseNo.label")}
                    />
                  </div>
                  <div className={classes.gridItem}>
                    <TextInputField
                      setFieldValue={setFieldValue}
                      value={values.buildingFloorDoor}
                      onChange={handleChange}
                      errors={errors}
                      name={"buildingFloorDoor"}
                      disabled={false}
                      label={t("rvm.stairFloorDoor.label")}
                    />
                  </div>
                  <div className={classes.gridItem}>
                    <TextInputField
                      setFieldValue={setFieldValue}
                      value={values.zipCode}
                      onChange={handleChange}
                      errors={errors}
                      name={"zipCode"}
                      disabled={false}
                      label={t("rvm.zip.label")}
                      required
                    />
                  </div>
                  <div className={classes.gridItem}>
                    <TextInputField
                      setFieldValue={setFieldValue}
                      value={values.city}
                      onChange={handleChange}
                      errors={errors}
                      name={"city"}
                      disabled={false}
                      label={t("rvm.city.label")}
                      required
                    />
                  </div>
                </div>
              </div>
              <DialogFooter>
                <DialogButton
                  text={t("rvm.addLocation.label")}
                  disabled={isLoading}
                  operationFn={() => {
                    setDidTrySubmit(true);
                    submitForm();
                  }}
                />
              </DialogFooter>
              {isLoading && <FormSpinner />}
              <MapModal
                location={{
                  latitude: values.latitude,
                  longitude: values.longitude,
                  formattedAddress: values.formattedAddress,
                }}
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                setFieldValue={setFieldValue}
              />
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default AddLocationDialog;

const classes = mergeStyleSets({
  mainFields: {
    display: "flex",
    padding: 0,
  },
  mapFields: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  mapAddress: {
    flex: "1 1 0%",
  },
  gridContainer: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "23% 23% 23% 25%",
    gap: "5px 20px",
    padding: 0,
  },
  gridContainerFull: {
    boxSizing: "border-box",
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: 20,
    padding: 0,
  },
  gridItem: {},
  iconInfo: {
    padding: "10px",
    paddingTop: "20px",
    cursor: "default",
  },
  gridItem2: { gridColumn: "auto / span 2" },
  gridItem3: { gridColumn: "auto / span 3" },
  gridItemCheckbox: {
    marginTop: "35px",
    marginLeft: "10px",
  },
  gridItemButton: {
    marginTop: "30px",
    marginLeft: "10px",
    textAlign: "right",
  },
  commentItem: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    gridRowStart: 1,
  },
  transparentBg: {
    background: "transparent",
  },
  locationContainer: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "80% 20%",
  },
  buttonContainer: {
    marginTop: "30px",
    textAlign: "right",
  },
  noPadding: {
    paddingLeft: "8px",
    marginTop: "15px",
  },
  break: {
    flexBasis: "100%",
    height: 0,
  },
});
