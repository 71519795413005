import { Sticky } from "@fluentui/react";
import { useConst } from "@uifabric/react-hooks";
import {
  ContextualMenu,
  DirectionalHint,
  getTheme,
  IRenderFunction,
  IScrollablePaneStyles,
  MarqueeSelection,
  ShimmeredDetailsList,
  StickyPositionType,
  TooltipDelay,
  TooltipHost,
  TooltipOverflowMode,
} from "office-ui-fabric-react";
import {
  CheckboxVisibility,
  ColumnActionsMode,
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  IColumn,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IDetailsHeaderStyles,
  IDetailsListProps,
  IDetailsListStyles,
  IDetailsRowStyles,
  IObjectWithKey,
  Selection,
  SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { centerElements, noContentText, scrollToTop } from "../config/ui";
import "./List.scss";

interface Iprops {
  /**
   * @summary Controls the visibility of selection check box.
   */
  checkboxVisibility?: CheckboxVisibility;
  /**
   * @summary Sets list class name
   */
  listClassName?: string;

  /**
   * @summary Sets list scrollable pane class name
   */
  scrollablePaneClassName?: string;

  /**
   * @summary Array of items which will be shown in the list
   */
  listItems: Array<any>; //TODO remove any

  /**
   * @summary Array of currently selected items in the list
   */
  selection?: Array<IObjectWithKey>;

  /**
   * @summary Function that sets currently selected array selections
   */
  setSelection?: (selection: IObjectWithKey[]) => void;

  /**
   * @summary List styles
   */
  listStyles?: Partial<IDetailsListStyles>;

  /**
   * @summary Scrollable pane styles
   */
  scrollablePaneStyle: Partial<IScrollablePaneStyles>;

  /**
   * @summary Array of column items
   */
  columns: Array<IColumn>;

  /**
   * @summary Selection type for list
   */
  selectionType?: SelectionMode;

  /**
   * @summary Function which is triggered when double clicking on item in the list
   */
  onItemInvoked?: (item: any) => void;

  /**
   * @summary List of context menu items
   */
  contextMenuItems?: (
    selection: IObjectWithKey[],
    item?: any,
    index?: number
  ) => any;

  /**
   * @summary If provided rows will have background colors set to provided values
   */
  alternateRowColors?: { oddColor: string; evenColor: string };

  /**
   * @summary Styles for rows
   */
  rowStyles?: Partial<IDetailsRowStyles>;

  /**
   * @summary Styles for header
   */
  headerStyles?: Partial<IDetailsHeaderStyles>;

  /**
   * @summary Show sticky header, overwrites onRenderRow function
   */
  shouldShowStickyHeader?: boolean;

  /**
   * @summary Function which sets column state outside of List component
   */
  setColumns: (columns: Array<IColumn>) => void;

  /**
   * @summary Controls if shimmer in list is shown
   */
  isLoading: boolean;

  /**
   * @summary Number of shimmer lines
   */
  shimmerLineLength: number;

  /**
   * @summary sort callback function
   */
  sortCallback?: (sort: string) => void;

  /**
   * @summary controls if fading overlay is shown in list
   */
  removeFadingOverlay: boolean;

  /**
   * @summary Sets event and item when triggered outside of list component in order to show context menu
   */
  contextMenuOnClick?: { event: any; item: any; index?: number };

  /**
   * @summary Determines if right click on context menu is disabled, default is set to false
   */
  isRightClickContextMenuDisabled?: boolean;

  /**
   * @summary Function that is triggered when clicked on column
   */
  onColumnClick?: (ev: React.MouseEvent<HTMLElement>, column: IColumn) => any;
  /**
   * @summary Determines if sorting is enabled/disabled
   */
  sortDisabled?: boolean;

  /**
   * @summary List constrain mode
   */
  constrainMode?: ConstrainMode;
  /**
   * @summary Header background color
   */
  loadMore?: {
    /**
     * @summary Infinite scrolling skip value
     */
    skip: number;
    /**
     * @summary Infinite scrolling count of items recieved from BE
     */
    itemCount: number;
    /**
     * @summary Infinite scrolling number of items to show per load
     */
    numberOfItemsToShow: number;
    /**
     * @summary Infinite scrolling observer element distance from bottom in px, eg. "10px"
     */
    distanceFromBottom: string;
    /**
     * @summary Infinite scrolling observer element class name
     */
    observerElementClassName: string;
    /**
     * @summary Infinite scrolling interesection observer options
     */
    options?: { rootMargin?: string; threshold?: number };
    /**
     * @summary Infinite scrolling function to set skip number in the parent component
     */
    updateSkip: Dispatch<SetStateAction<number>>;

    isLoading: boolean;
  };
  /**
   * @summary Disables clickling on sort in columns
   */
  isSortClickable?: boolean;

  /**
   * @summary Shows this string if items length is zero
   */
  emptyListMessage?: string;
  /**
   * @summary Indicator to show @param emptyListMessage string instead @param noResultText string  if items length is zero, when infinite scrolling is enabled on list
   */
  showListWithEmptyListMessage?: boolean;
  /**
   * @summary Shows some text if list is empty
   */
  noResultText?: string;
  disableVirtualize?: boolean;
}

export const List = (props: Iprops): JSX.Element => {
  const { t } = useTranslation();
  const {
    emptyListMessage = t("crm.NoRecords"),
    isRightClickContextMenuDisabled = false,
  } = props;
  const [contextMenu, setContextMenu] = useState<any>({});
  const [shouldShowNoRecords, setShouldShowNoRecords] =
    useState<boolean>(false);
  const theme = getTheme();
  const scrollablePaneRef = useRef<any>();

  const selection = useConst(
    () =>
      new Selection({
        selectionMode: props.selectionType
          ? props.selectionType
          : SelectionMode.none,
        onSelectionChanged: () => {
          props.setSelection && props.setSelection(selection.getSelection());
        },
      })
  );

  const onItemInvoked = (item) => {
    props.onItemInvoked && props.onItemInvoked(item);
  };

  const generateContextMenuItems = (items?: any, ev?: any) => {
    return {
      items: items,
      target: { x: ev.pageX, y: ev.pageY },
      gapSpace: 10,
      onDismiss: () => {
        setContextMenu({});
      },
    };
  };

  const onItemContextMenu = (
    item: any,
    index: number | undefined,
    ev?: Event | undefined
  ): boolean => {
    if (isRightClickContextMenuDisabled && props.contextMenuItems) {
      //@ts-ignore
      if (ev?.which !== 3) {
        setContextMenu(
          generateContextMenuItems(
            props.contextMenuItems(selection.getSelection(), item, index),
            ev
          )
        );
      }
    } else {
      if (props.contextMenuItems) {
        setContextMenu(
          generateContextMenuItems(
            props.contextMenuItems(selection.getSelection(), item, index),
            ev
          )
        );
      }
    }

    return false;
  };

  const onRenderRow: IDetailsListProps["onRenderRow"] = (prop) => {
    const customStyles: Partial<IDetailsRowStyles> = props.rowStyles
      ? { ...props.rowStyles }
      : {};

    if (prop) {
      if (props.alternateRowColors) {
        customStyles.root = {
          ...(customStyles.root as {}),
          backgroundColor:
            prop.itemIndex % 2 === 0
              ? props.alternateRowColors.evenColor
              : props.alternateRowColors.oddColor,
        };
      }

      if (prop.item.noRecords)
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "12px 0",
              margin: 0,
              backgroundColor: theme.palette.white,
              fontSize: "14px",
              borderBottom: `1px solid ${theme.palette.neutralLighter}`,
            }}
          >
            {props.noResultText ? t(props.noResultText) : t("rvm.NoRecords")}
          </p>
        );

      //Add onRenderRow override here, if it is needed later on
      return <DetailsRow {...prop} styles={customStyles} />;
    }
    return null;
  };

  const onRenderItemColumn = (
    item?: IColumn,
    index?: number | undefined,
    column?: IColumn | undefined
  ) => {
    if (!column || !item) return null;

    return (
      <div>
        <TooltipHost
          overflowMode={TooltipOverflowMode.Self}
          content={column.fieldName ? item[column.fieldName] : item[column.key]}
          calloutProps={{
            gapSpace: 0,
          }}
          delay={TooltipDelay.zero}
          styles={{
            root: {
              display: "block",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "help",
            },
          }}
          directionalHint={DirectionalHint.bottomCenter}
        >
          {column.fieldName ? item[column.fieldName] : item[column.key]}
        </TooltipHost>
      </div>
    );
  };

  const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
    prop,
    defaultRender
  ) => {
    if (prop) {
      const onRenderColumnHeaderTooltip: IRenderFunction<
        IDetailsColumnRenderTooltipProps
      > = (
        tooltipHostProps: any,
        columnHeaderDefaultRender?: IRenderFunction<IDetailsColumnRenderTooltipProps>
      ) => {
        return (
          <TooltipHost
            content={tooltipHostProps.column?.name}
            id={tooltipHostProps.column?.key}
            calloutProps={{ gapSpace: 0 }}
            styles={{
              root: {
                display: "block",
                width: "100%",
                height: "100%",
                cursor: "help",
              },
            }}
            directionalHint={DirectionalHint.topLeftEdge}
          >
            {columnHeaderDefaultRender!({
              ...tooltipHostProps,
            })}
          </TooltipHost>
        );
      };

      if (props.shouldShowStickyHeader) {
        return (
          <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
              ...prop,
              styles: {
                ...props.headerStyles,
                root:
                  typeof props.headerStyles?.root !== "object" &&
                  props.headerStyles?.root !== undefined
                    ? props.headerStyles?.root
                    : {
                        // backgroundColor: theme.palette.themeLighterAlt,
                        // ...props.headerStyles?.root,
                      },
              },
              onRenderColumnHeaderTooltip,
            })}
          </Sticky>
        );
      }
      //Add onRenderDetails override here, if it is needed later on
      return (
        <>
          {defaultRender!({
            ...prop,
            styles: {
              ...props.headerStyles,
              root:
                typeof props.headerStyles?.root !== "object" &&
                props.headerStyles?.root !== undefined
                  ? props.headerStyles?.root
                  : {
                      // backgroundColor: theme.palette.themeLighterAlt,
                      // ...props.headerStyles?.root,
                    },
            },
            onRenderColumnHeaderTooltip,
          })}
        </>
      );
    }

    return null;
  };

  const onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ) => {
    if (column.columnActionsMode !== ColumnActionsMode.disabled) {
      scrollToTop(scrollablePaneRef);
      if (props.columns) {
        const destructureColumns = [...props.columns];
        let sortString: string = "";
        const columns = destructureColumns.map((currentColumn: IColumn) => {
          if (currentColumn.key === column.key) {
            if (currentColumn.isSorted) {
              if (currentColumn.isSortedDescending) {
                currentColumn.iconClassName = "";
                currentColumn.isSorted = false;
                sortString = "unsorted";
              } else {
                currentColumn.isSortedDescending =
                  !currentColumn.isSortedDescending;
                currentColumn.isSorted = true;
                currentColumn.iconClassName = "hide";
                sortString = `${currentColumn.key} ${
                  currentColumn.isSortedDescending ? "desc" : "asc"
                }`;
              }
            } else {
              currentColumn.isSortedDescending = false;
              currentColumn.isSorted = true;
              currentColumn.iconClassName = "hide";
              sortString = `${currentColumn.key} asc`;
            }
          } else {
            currentColumn.isSorted = false;
            currentColumn.iconClassName = "";
          }
          return currentColumn;
        });
        props.sortCallback && props.sortCallback(sortString);
        props.setColumns(columns);
      }
    }
  };

  useEffect(() => {
    if (props.contextMenuOnClick) {
      onItemContextMenu(
        props.contextMenuOnClick.item,
        props.contextMenuOnClick.index,
        props.contextMenuOnClick.event
      );
    }
  }, [props.contextMenuOnClick]);

  //Infinite scrolling intersection observer intialisation
  useEffect(() => {
    if (props.loadMore) {
      let options = {
        root: scrollablePaneRef.current && scrollablePaneRef.current.root,
        rootMargin: props.loadMore?.options?.rootMargin
          ? props.loadMore?.options?.rootMargin
          : "0px",
        threshold: props.loadMore?.options?.threshold
          ? props.loadMore?.options?.threshold
          : 0.1,
      };

      const target = document.querySelector(
        `.${props.loadMore.observerElementClassName}`
      );

      let observer = new IntersectionObserver((entries: any) => {
        const first: any = entries[0];
        if (first.isIntersecting) {
          if (props.loadMore) {
            props.loadMore.updateSkip(
              props.loadMore.skip + props.loadMore?.numberOfItemsToShow
            );
          }
        }
      }, options);

      if (target) {
        observer.observe(target);
      }
      return () => {
        observer.disconnect();
      };
    }
  });

  useEffect(() => {
    if (props.loadMore && !props.loadMore?.isLoading && !props.isLoading) {
      setShouldShowNoRecords(
        !props.showListWithEmptyListMessage &&
          !Boolean(props.loadMore.itemCount)
      );
    }
  }, [props.loadMore?.isLoading, props.isLoading, props.loadMore?.itemCount]);

  return (
    <div
      className={`reusableListComponent${props.sortDisabled ? " noSort" : ""}`}
    >
      {!shouldShowNoRecords ? (
        <>
          <MarqueeSelection
            selection={selection}
            isEnabled={selection.mode === SelectionMode.multiple}
          >
            <ShimmeredDetailsList
              setKey="items"
              checkboxVisibility={props.checkboxVisibility}
              enableShimmer={props.isLoading}
              items={props.listItems}
              //TODOPedja: I added this but it violates this generic component
              columns={props.columns.filter((c: any) => {
                return c.isVisible === false ? false : true;
              })}
              selection={selection}
              selectionMode={
                props.selectionType ? props.selectionType : SelectionMode.none
              }
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={onItemInvoked}
              onItemContextMenu={onItemContextMenu}
              onRenderRow={onRenderRow}
              onRenderItemColumn={onRenderItemColumn}
              onRenderDetailsHeader={onRenderDetailsHeader}
              shimmerLines={props.shimmerLineLength}
              removeFadingOverlay={props.removeFadingOverlay}
              constrainMode={
                props.constrainMode !== undefined
                  ? props.constrainMode
                  : undefined
              }
              onColumnHeaderClick={(
                ev: React.MouseEvent<HTMLElement> | undefined,
                column: IColumn | undefined
              ) => {
                if (!props.sortDisabled && !props?.isSortClickable) {
                  if (ev && column) {
                    props.onColumnClick
                      ? props.onColumnClick(ev, column)
                      : onColumnClick(ev, column);
                  }
                }
              }}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              onShouldVirtualize={() =>
                props.disableVirtualize === undefined ? true : false
              }
            />
          </MarqueeSelection>
          {props.loadMore && !props.isLoading && !props.loadMore.isLoading ? (
            props.loadMore.skip < props.loadMore.itemCount &&
            props.loadMore.itemCount > props.loadMore.numberOfItemsToShow ? (
              <div
                style={{
                  height: "10px",
                  width: "100%",
                  position: "relative",
                  bottom: props.loadMore.distanceFromBottom,
                }}
                className={props.loadMore.observerElementClassName}
              ></div>
            ) : null
          ) : null}
          {contextMenu ? <ContextualMenu {...contextMenu} /> : null}
        </>
      ) : (
        <div style={{ ...noContentText, ...centerElements }}>
          {/* Move translation out of list component if its needed in the future, or pass noRecords string as props */}
          {emptyListMessage}
        </div>
      )}
    </div>
  );
};
