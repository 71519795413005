import styled from "@emotion/styled";
import { IconButton, Persona } from "@fluentui/react";
import { msal } from "../../api/auth";
import { zIndex } from "../../config/zIndex";
import { useSelector } from "../../store/hooks";

export type ModalState = {
  isOpen: boolean;
  contentId: number | string | null;
};

export const AuthSidebar = () => {
  const email = useSelector((s) => s.userRole.email);
  return (
    <Root>
      <StyledLink
        onClick={() => {
          msal.logout();
        }}
      >
        Logout
      </StyledLink>
      <Persona text={email ? email : ""} />
      {/* <CurrentInsuredPersonDisplay /> */}
    </Root>
  );
};

const StyledLink = styled.p`
  text-decoration: none;
  color: ${(p) => p.theme.palette.themePrimary};
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  &.active {
    border-color: #4ce6ff;
  }
  text-align: right;
`;

const Root = styled.div`
  position: fixed;
  top: 50px;
  right: 0;
  background: white;
  width: 350px;
  height: calc(100vh - 50px);
  box-shadow: -3px 2px 12px -5px rgba(0, 0, 0, 0.29);
  z-index: ${zIndex.rightSidebarButton};
  padding: 10px 25px 0 25px;
  font-size: 14px;
  overflow-y: auto;
  @media (max-width: 350px) {
    width: 100%;
    padding: 10px 15px 0 15px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const ModalTitle = styled.h1`
  font-size: 20px;
  flex: 1;
  margin-right: 5px;
`;

const ModalCloseButton = styled(IconButton)`
  margin-left: auto;
`;

const ModalBody = styled.div`
  padding: 20px;
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 72px - 30px);
  pre {
    word-wrap: pre-wrap;
  }
`;
