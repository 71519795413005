import { INavLink, Nav } from "@fluentui/react";
import React, { useState } from "react";
import { IAnchorNavConfigObj } from "./AnchorNavInterface";
import { anchorNavStyles } from "./TabStyleConsts";

export const AnchorNav = ({
  groups,
  onLinkClick,
  startSelectedKey,
}: IAnchorNavConfigObj): JSX.Element => {
  const [activeKey, setActiveKey] = useState<string>(startSelectedKey);

  const overrideOnLinkClick = (
    ev?: React.MouseEvent<HTMLElement>,
    item?: INavLink
  ) => {
    onLinkClick(ev, item);

    if (item) setActiveKey(item.key ? item.key : "");
  };

  return (
    <Nav
      styles={anchorNavStyles}
      groups={groups}
      onLinkClick={overrideOnLinkClick}
      selectedKey={activeKey}
    />
  );
};
