import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { DefaultButton, Icon } from "@fluentui/react";
import clsx from "clsx";
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";

import { useTranslation } from "react-i18next";
import { ClearButton, Flex } from "./styled";

const Dropzone = ({ formikProps, file, setFile, filename, setFilename }) => {
  const { t } = useTranslation();

  const formik = formikProps;

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      // accept: [
      //   "application/vnd.ms-excel",
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // ],
      multiple: false,
      onDropAccepted: (files) => {
        setFile(files[0]);
      },
    });
  useEffect(() => {
    formik && formik?.setFieldValue("file", file);
  }, [file, formik?.values]);
  return (
    <Root
      className={clsx({
        accept: isDragAccept,
        reject: isDragReject,
      })}
      {...getRootProps()}
    >
      <input {...(getInputProps() as any)} />
      {file || filename ? (
        <Flex
          direction="column"
          gap={10}
          css={css`
            width: 100%;
            overflow: hidden;
            flex-grow: 1;
          `}
        >
          <Flex
            css={css`
              padding: 0 20px;
              width: 100%;
              overflow: hidden;
              flex-grow: 1;
            `}
            gap={5}
            justifyContent="center"
          >
            {file ? (
              <>
                <ExcelIcon iconName="Document" />
                <FileName title={file.name}>{file.name}</FileName>
                <ClearButton
                  iconProps={{ iconName: "Cancel" }}
                  onClick={() => {
                    setFile(null);
                  }}
                />
              </>
            ) : (
              <>
                <ExcelIcon
                  iconName="Document"
                  onClick={() => window.open(filename)}
                />
                <FileNameLink
                  onClick={() => window.open(filename)}
                  title={filename.split("/").pop().split("#")[0].split("?")[0]}
                >
                  {filename.split("/").pop().split("#")[0].split("?")[0]}
                </FileNameLink>
                <ClearButton
                  iconProps={{ iconName: "Cancel" }}
                  onClick={() => {
                    setFilename(null);
                  }}
                />
              </>
            )}
          </Flex>
          <DefaultButton
            text={t("rvm.chooseDifferentFile")}
            onClick={() => open()}
          />
        </Flex>
      ) : (
        <Flex direction="column" gap={5}>
          <UploadIcon iconName="CloudUpload" />
          <TitleContainer>
            <Title>{t("rvm.dragFileHere")}</Title>
            <Title>{t("or")}</Title>
          </TitleContainer>
          <DefaultButton text={t("rvm.browseFiles")} onClick={open} />
        </Flex>
      )}
    </Root>
  );
};
export default React.memo(Dropzone);
const Root = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${(p) => p.theme.palette.neutralTertiaryAlt};
  height: auto;
  padding: 10px;
  margin: 20px;
  font-size: 18px;
  font-weight: 600;
  flex-direction: column;
  user-select: none;
  &:focus {
    outline: none;
  }
  &.accept {
    background-color: ${(p) => p.theme.palette.themeLighterAlt};
  }
  &.reject {
    background-color: ${(p) => p.theme.palette.redDark};
  }
`;

const TitleContainer = styled.div`
  text-align: center;
`;

const FileName = styled.div`
  font-size: 20px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileNameLink = styled.div`
  cursor: pointer;
  font-size: 20px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  font-size: 20px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const UploadIcon = styled(Icon)`
  font-size: 35px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const ExcelIcon = styled(Icon)`
  font-size: 25px;
  color: ${(p) => p.theme.palette.greenDark};
`;
