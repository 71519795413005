import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import i18next from "i18next";
import moment from "moment";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api/api";
import { toast } from "../../components/FluentToast";
import { ExcelReportResponse } from "../../types/types";
import { locationAddressToString } from "../../utils/utils";
import { loadLocationsRoutine } from "../locations/locations";
import { LoadStatus } from "../store";
import { createAsyncRoutine, handleAxiosError } from "../util";

export type ValueMonitoringPageState = {
  clientName: any;
  clientNameOptions: any[];
  location: any;
  locationOptions: any[];
  division: any;
  divisionOptions: any[];
  companyCode: any;
  companyCodeOptions: any[];
  address: any;
  addressOptions: any[];
  locationType: any;
  locationTypeOptions: any[];
  errors: {};
  data: any[];
  historyData: any[];
  formObject: any;
  filteredData: any[];
  dataLoadStatus: LoadStatus;
  generateReportLoadStatus: LoadStatus;
  showInvitationForm: boolean;
  showReminderForm: boolean;
  selectedLocation: any;
  showActiveLocationsOnly: boolean;
  excelReportResponse: ExcelReportResponse;

  deleteDialog: {
    clientId: number;
    locationId: number;
    propertyInfoId: number;
    rowVersion: string;
    isOpen: boolean;
    isLoading: boolean;
  };
  addLocationDialog: {
    clientId: number;
    locationName: string;
    divisionName: string;
    locationStatus: number;
    locationOwningStatus: number;
    locationType: number;
    companyCode: string;
    statusCode: number;
    address: {
      addressTypeCodeId: number; //768
      street: string;
      house: string;
      buildingFloorDoor: string;
      zipCode: string;
      city: string;
      countryCodeID: number;
      isMainAddress: boolean;
      partnerID: number;
      positionLatitude: number;
      positionLongitude: number;
    };
    isOpen: boolean;
    isLoading: boolean;
  };
  excelDialog: {
    isOpen: boolean;
    icon?: string;
    title: string;
    color: string;
    data?: {
      [key: string]: any;
    };
  };
};
export const addPropertyInfoActionsRoutine = createAsyncRoutine<
  {
    clientId: any;
    formObject: any;

    onSuccess?: (clientId, locationId, propertyInformationId) => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any,
  any
>("valueMonitoring/addPropertyInfo");

export const addLocationRoutine = createAsyncRoutine<
  {
    clientId: number;
    formObject: any;

    onSuccess?: (location) => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any,
  any
>("valueMonitoring/addLocation");

export const generateReportActions = createAsyncRoutine<
  any,
  void,
  ExcelReportResponse,
  any
>("valueMonitoring/generateExcelReport");

export const sendReminderRoutine = createAsyncRoutine<
  {
    formObject: any;

    onSuccess?: () => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any,
  any
>("valueMonitoring/sendReminder");

const initialState: ValueMonitoringPageState = {
  clientName: null,
  clientNameOptions: [],
  location: null,
  locationOptions: [],
  division: null,
  divisionOptions: [],
  companyCode: null,
  companyCodeOptions: [],
  address: null,
  addressOptions: [],
  locationType: null,
  locationTypeOptions: [],
  errors: {},
  data: [],
  filteredData: [],
  dataLoadStatus: "none",
  generateReportLoadStatus: "none",
  selectedLocation: null,
  historyData: [],
  formObject: null,
  showActiveLocationsOnly: false,
  excelReportResponse: null,

  showInvitationForm: false,
  showReminderForm: false,
  deleteDialog: {
    clientId: null,
    locationId: null,
    propertyInfoId: null,
    rowVersion: null,
    isOpen: false,
    isLoading: false,
  },
  addLocationDialog: {
    clientId: null,
    locationName: null,
    companyCode: null,
    divisionName: null,
    locationStatus: null,
    locationOwningStatus: null,
    locationType: null,
    statusCode: null,
    address: {
      addressTypeCodeId: null,
      buildingFloorDoor: null,
      city: null,
      countryCodeID: null,
      house: null,
      isMainAddress: null,
      partnerID: null,
      positionLatitude: null,
      positionLongitude: null,
      street: null,
      zipCode: null,
    },

    isOpen: false,
    isLoading: false,
  },
  excelDialog: {
    isOpen: false,
    icon: "",
    title: "",
    color: "",
  },
};

export const valueMonitoringPageSlice = createSlice({
  name: "valueMonitoringPage",
  initialState,
  reducers: {
    resetSearchForm: (s) => {
      s.showActiveLocationsOnly = false;
      s.clientName = s.clientNameOptions.length === 1 ? s.clientName : null;
      s.location = null;
      s.division = null;
      s.companyCode = null;
      s.address = null;
      s.locationType = null;
      s = filterFormValues(s, "");
    },
    setDeleteDialog: (
      s,
      a: PayloadAction<ValueMonitoringPageState["deleteDialog"]>
    ) => {
      s.deleteDialog = a.payload;
    },
    setAddLocationDialog: (
      s,
      a: PayloadAction<ValueMonitoringPageState["addLocationDialog"]>
    ) => {
      s.addLocationDialog = a.payload;
    },
    openAddLocationDialog: (s, a: PayloadAction<void>) => {
      s.addLocationDialog = {
        ...initialState.addLocationDialog,
        isOpen: true,
      };
    },
    setShowActiveLocationsOnly: (
      s,
      a: PayloadAction<ValueMonitoringPageState["showActiveLocationsOnly"]>
    ) => {
      s.showActiveLocationsOnly = a.payload;
      s.location = null;
      s.division = null;
      s.companyCode = null;
      s.address = null;
      s.locationType = null;
      s = filterFormValues(s, "");
    },
    setExcelReportResponse: (
      s,
      a: PayloadAction<ValueMonitoringPageState["excelReportResponse"]>
    ) => {
      s.excelReportResponse = a.payload;
    },
    setHistoryData: (
      s,
      a: PayloadAction<ValueMonitoringPageState["historyData"]>
    ) => {
      s.historyData = a.payload;
      if (s.historyData.length === 0) {
        s.formObject = null;
      }
    },
    setFormObject: (s, a: any) => {
      s.formObject = a.payload;
      // s.selectedLocation = {
      //   location: s.selectedLocation.location,
      //   client: s.selectedLocation.client,
      //   ...a.payload,
      // };
    },
    setClientName: (
      s,
      a: PayloadAction<ValueMonitoringPageState["clientName"]>
    ) => {
      s.clientName = a.payload;
      s.division = null;
      s.locationType = null;
      s.companyCode = null;
      s.address = null;
      s.location = null;
      s.showActiveLocationsOnly = false;
      s = filterFormValues(s, a.payload);
    },
    setClientNameForSingleClient: (
      s,
      a: PayloadAction<ValueMonitoringPageState["clientName"]>
    ) => {
      s.clientName = a.payload;
      s.division = null;
      s.locationType = null;
      s.companyCode = null;
      s.address = null;
      //s.location = null;
      s.showActiveLocationsOnly = false;
      s = filterFormValues(s, a.payload);
    },

    setDivision: (
      s,
      a: PayloadAction<ValueMonitoringPageState["division"]>
    ) => {
      s.division = a.payload;
      s.locationType = null;
      s.companyCode = null;
      s.address = null;
      s.location = null;

      s = filterFormValues(s, a.payload);
    },

    setLocationType: (
      s,
      a: PayloadAction<ValueMonitoringPageState["locationType"]>
    ) => {
      s.locationType = a.payload;
      s.companyCode = null;
      s.address = null;
      s.location = null;
      s = filterFormValues(s, a.payload);
    },
    setShowInvitationForm: (
      s,
      a: PayloadAction<ValueMonitoringPageState["showInvitationForm"]>
    ) => {
      s.showInvitationForm = a.payload;
    },
    setShowReminderForm: (
      s,
      a: PayloadAction<ValueMonitoringPageState["showReminderForm"]>
    ) => {
      s.showReminderForm = a.payload;
    },
    setCompanyCode: (
      s,
      a: PayloadAction<ValueMonitoringPageState["companyCode"]>
    ) => {
      s.companyCode = a.payload;
      s.location = null;
      s = filterFormValues(s, a.payload);
    },
    setAddress: (s, a: PayloadAction<ValueMonitoringPageState["address"]>) => {
      s.address = a.payload;
      s.companyCode = null;
      s.location = null;
      s = filterFormValues(s, a.payload);
    },
    setLocation: (
      s,
      a: PayloadAction<ValueMonitoringPageState["location"]>
    ) => {
      s.location = a.payload.item;
      if (a.payload.resetFilters) {
        s.division = null;
        s.locationType = null;
      }
      s.companyCode = null;
      s.address = null;
      s = filterFormValues(s, a.payload.item);
    },
    resetState: (s) => {
      Object.keys(s).forEach((key) => {
        s[key] = initialState[key];
      });
    },
    hideDeletePropertyInfoDialog: (s, a: any) => {
      s.deleteDialog = { ...initialState.deleteDialog };
    },
    hideAddLocationDialog: (s, a: any) => {
      s.addLocationDialog = { ...initialState.addLocationDialog };
    },
    setExcelDialog: (
      s,
      a: PayloadAction<Partial<ValueMonitoringPageState["excelDialog"]>>
    ) => {
      s.excelDialog = { ...s.excelDialog, ...a.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadPropertyInfoRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(loadPropertyInfoRoutine.success, (s, a) => {
      s.data = a.payload;
      s.clientNameOptions = [
        ...new Set(s.data.map((el) => el.client.clientName)),
      ].map((el) => {
        return { label: el, value: el };
      });
      s.locationOptions = s.data
        .map((el) => {
          return {
            label: el.location.locationName,
            value: el.location.locationId,
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.divisionOptions = [
        ...new Set(s.data.map((el) => el.location.divisionName)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.companyCodeOptions = [
        ...new Set(s.data.map((el) => el.location.companyCode)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.addressOptions = [
        ...new Set(s.data.map((el) => locationAddressToString(el.location))),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.locationTypeOptions = [
        ...new Set(s.data.map((el) => el.location.locationType)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.dataLoadStatus = "success";
    });
    builder.addCase(loadPropertyInfoRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(loadPropertyInfoForClientRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(loadPropertyInfoForClientRoutine.success, (s, a) => {
      s.data = a.payload;
      s.clientNameOptions = [
        ...new Set(s.data.map((el) => el.client.clientName)),
      ]
        .map((el) => {
          return { label: el, value: el };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.locationOptions = s.data
        .map((el) => {
          return {
            label: el.location.locationName,
            value: el.location.locationId,
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.divisionOptions = [
        ...new Set(s.data.map((el) => el.location.divisionName)),
      ]
        .map((el) => {
          return { label: el, value: el };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.companyCodeOptions = [
        ...new Set(s.data.map((el) => el.location.companyCode)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.addressOptions = [
        ...new Set(s.data.map((el) => locationAddressToString(el.location))),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.locationTypeOptions = [
        ...new Set(s.data.map((el) => el.location.locationType)),
      ]
        .map((el) => {
          return { label: el, value: el };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.dataLoadStatus = "success";
    });
    builder.addCase(loadPropertyInfoForClientRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(getPropertyInfoRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(getPropertyInfoRoutine.success, (s, a) => {
      s.data = [a.payload];
      s.clientNameOptions = [
        ...new Set(s.data.map((el) => el.client.clientName)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.locationOptions = s.data
        .map((el) => {
          return {
            label: el.location.locationName,
            value: el.location.locationId,
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.divisionOptions = [
        ...new Set(s.data.map((el) => el.location.divisionName)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.companyCodeOptions = [
        ...new Set(s.data.map((el) => el.location.companyCode)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.addressOptions = [
        ...new Set(s.data.map((el) => locationAddressToString(el.location))),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.locationTypeOptions = [
        ...new Set(s.data.map((el) => el.location.locationType)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.dataLoadStatus = "success";
    });
    builder.addCase(getPropertyInfoRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(getLastPropertyInfoRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(getLastPropertyInfoRoutine.success, (s, a) => {
      s.data = [a.payload];
      s.clientNameOptions = [
        ...new Set(s.data.map((el) => el.client.clientName)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.locationOptions = s.data
        .map((el) => {
          return {
            label: el.location.locationName,
            value: el.location.locationId,
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.divisionOptions = [
        ...new Set(s.data.map((el) => el.location.divisionName)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.companyCodeOptions = [
        ...new Set(s.data.map((el) => el.location.companyCode)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.addressOptions = [
        ...new Set(s.data.map((el) => locationAddressToString(el.location))),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.locationTypeOptions = [
        ...new Set(s.data.map((el) => el.location.locationType)),
      ]
        .map((el) => {
          return {
            label: el ? el : i18next.t("rvm.noValue.label"),
            value: el ? el : "",
          };
        })
        .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
      s.dataLoadStatus = "success";
    });
    builder.addCase(getLastPropertyInfoRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(loadPropertyInfoForLocationRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(loadPropertyInfoForLocationRoutine.success, (s, a) => {
      s.historyData = a.payload.data
        .sort((a, b) => {
          const aDate = moment(a.coveragePeriodToDate).toDate();
          const bDate = moment(b.coveragePeriodToDate).toDate();
          return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
        })
        .reverse();
      if (s.historyData.length > 0) {
        s.formObject = s.historyData.find(
          (el) =>
            el.propertyInformationId + "" ===
            a.payload.propertyInformationId + ""
        );
      } else {
        s.formObject = null;
      }
      s.dataLoadStatus = "success";
    });
    builder.addCase(loadPropertyInfoForLocationRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(updatePropertyInfoActionsRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(updatePropertyInfoActionsRoutine.success, (s, a) => {
      s.formObject = a.payload;
      //s.selectedLocation = a.payload;
      s.dataLoadStatus = "success";
    });

    builder.addCase(updatePropertyInfoActionsRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(addLocationRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(addLocationRoutine.success, (s, a) => {
      //s.formObject = a.payload;
      // s.location = {
      //   value: a.payload.location.locationName,
      //   label: a.payload.location.locationName,
      // };
      s.dataLoadStatus = "success";
    });

    builder.addCase(addLocationRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(loadLocationsRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(loadLocationsRoutine.success, (s, a) => {
      s.formObject = a.payload;
      s.dataLoadStatus = "success";
    });

    builder.addCase(loadLocationsRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(sendReminderRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(sendReminderRoutine.success, (s, a) => {
      // const updatedValues = a.payload;
      s.dataLoadStatus = "success";
    });
    builder.addCase(sendReminderRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });
    builder.addCase(generateReportActions.loading, (s) => {
      s.generateReportLoadStatus = "loading";
    });
    builder.addCase(generateReportActions.success, (s, a) => {
      s.generateReportLoadStatus = "success";
      s.excelReportResponse = a.payload;
    });
    builder.addCase(generateReportActions.error, (s) => {
      s.generateReportLoadStatus = "error";
    });

    builder.addCase(approvePropertyInfoActionsRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(approvePropertyInfoActionsRoutine.success, (s, a) => {
      s.formObject = a.payload;
      //s.selectedLocation = a.payload;
      s.dataLoadStatus = "success";
    });

    builder.addCase(approvePropertyInfoActionsRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });
    builder.addCase(rejectPropertyInfoActionsRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(rejectPropertyInfoActionsRoutine.success, (s, a) => {
      s.formObject = a.payload;
      s.selectedLocation = a.payload;
      s.dataLoadStatus = "success";
    });

    builder.addCase(rejectPropertyInfoActionsRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });

    builder.addCase(addPropertyInfoActionsRoutine.loading, (s, a) => {
      s.dataLoadStatus = "loading";
    });
    builder.addCase(addPropertyInfoActionsRoutine.success, (s, a) => {
      // s.selectedLocation = a.payload[0];
      s.formObject = a.payload[0];
      s.dataLoadStatus = "success";
    });

    builder.addCase(addPropertyInfoActionsRoutine.error, (s, a) => {
      s.dataLoadStatus = "error";
    });
  },
});

const filterFormValues = (s: any, setValue: string): any => {
  s.filteredData = filterData(
    s.data,
    s.location,
    s.clientName,
    s.division,
    s.locationType,
    s.companyCode,
    s.address,
    s.showActiveLocationsOnly
  );
  if (s.filteredData.length === 1) {
    if (setValue !== null) {
      s.clientName = {
        value: s.filteredData[0].client.clientName,
        label: s.filteredData[0].client.clientName,
      };
      s.division = {
        value: s.filteredData[0].location.divisionName
          ? s.filteredData[0].location.divisionName
          : "",
        label: s.filteredData[0].location.divisionName
          ? s.filteredData[0].location.divisionName
          : i18next.t("rvm.noValue.label"),
      };
      s.locationType = {
        value: s.filteredData[0].location.locationType
          ? s.filteredData[0].location.locationType
          : "",
        label: s.filteredData[0].location.locationType
          ? s.filteredData[0].location.locationType
          : i18next.t("rvm.noValue.label"),
      };
      s.companyCode = {
        value: s.filteredData[0].location.companyCode
          ? s.filteredData[0].location.companyCode
          : "",
        label: s.filteredData[0].location.companyCode
          ? s.filteredData[0].location.companyCode
          : i18next.t("rvm.noValue.label"),
      };
      s.address = {
        value: locationAddressToString(s.filteredData[0].location),
        label: locationAddressToString(s.filteredData[0].location)
          ? locationAddressToString(s.filteredData[0].location)
          : i18next.t("rvm.noValue.label"),
      };
      s.location = {
        value: s.filteredData[0].location.locationId,
        label: s.filteredData[0].location.locationName,
      };
      s.selectedLocation = s.filteredData[0];
    }
  }

  if (s.location === null) {
    s.selectedLocation = null;
  }

  //s.clientNameOptions = filterClientNameOptions(s.filteredData);

  const clientFilteredDataOnly = filterData(
    s.data,
    null,
    s.clientName,
    null,
    null,
    null,
    null,
    s.showActiveLocationsOnly
  );

  s.divisionOptions = filterDivisionOptions(clientFilteredDataOnly);

  const clientAndDivisionFilteredDataOnly = filterData(
    s.data,
    null,
    s.clientName,
    s.division,
    null,
    null,
    null,
    s.showActiveLocationsOnly
  );
  s.locationTypeOptions = filterLocationTypeOptions(
    clientAndDivisionFilteredDataOnly
  );

  const clientAndDivisionAndLocationTypeFilteredDataOnly = filterData(
    s.data,
    null,
    s.clientName,
    s.division,
    s.locationType,
    null,
    null,
    s.showActiveLocationsOnly
  );

  const clientAndDivisionAndLocationTypeAndCompanyCodeFilteredDataOnly =
    filterData(
      s.data,
      null,
      s.clientName,
      s.division,
      s.locationType,
      s.companyCode,
      s.address,
      s.showActiveLocationsOnly
    );

  s.companyCodeOptions = filterCompanyCodeOptions(
    clientAndDivisionAndLocationTypeAndCompanyCodeFilteredDataOnly
  );
  s.addressOptions = filterAddressOptions(
    clientAndDivisionAndLocationTypeAndCompanyCodeFilteredDataOnly
  );
  s.locationOptions = filterLocationOptions(
    clientAndDivisionAndLocationTypeAndCompanyCodeFilteredDataOnly
  );

  return s;
};
const filterData = (
  data: any[],
  location: any,
  clientName: any,
  division: any,
  locationType: any,
  companyCode: any,
  address: any,
  showActiveLocationsOnly: boolean
): any[] => {
  let retVal = [];
  if (!data) return retVal;

  retVal = [
    ...data.map((el) => {
      return { ...el, client: { ...el.client }, location: { ...el.location } };
    }),
  ];

  if (showActiveLocationsOnly) {
    retVal = retVal.filter(
      (el) =>
        el.statusCode === 3 && el.location.statusCode !== 6 ? true : false // location status code 6 means disabled location status
    );
  }

  retVal = retVal.filter((el) =>
    location ? el.location.locationId === location.value : true
  );

  retVal = retVal.filter((el) =>
    clientName ? el.client.clientName === clientName.value : true
  );

  retVal = retVal.filter((el) =>
    division
      ? (el.location.divisionName ? el.location.divisionName : "") ===
        division.value
      : true
  );

  // retVal = retVal.filter((el) =>
  // location ? el.location.locationName === location : true);

  retVal = retVal.filter((el) =>
    locationType
      ? (el.location.locationType ? el.location.locationType : "") ===
        locationType.value
      : true
  );

  retVal = retVal.filter((el) => {
    return address
      ? locationAddressToString(el.location) === address.value
      : true;
  });

  retVal = retVal.filter((el) =>
    companyCode
      ? (el.location.companyCode ? el.location.companyCode : "") ===
        companyCode.value
      : true
  );

  return retVal;
};

const filterLocationOptions = (filteredData: any[]) => {
  return filteredData
    .map((el) => {
      return { label: el.location.locationName, value: el.location.locationId };
    })
    .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
};

const filterClientNameOptions = (filteredData: any[]) => {
  return [...new Set(filteredData.map((el) => el.client.clientName))]
    .map((el) => {
      return {
        label: el ? el : i18next.t("rvm.noValue.label"),
        value: el ? el : "",
      };
    })
    .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
};

const filterCompanyCodeOptions = (filteredData: any[]) => {
  return [...new Set(filteredData.map((el) => el.location.companyCode))]
    .map((el) => {
      return {
        label: el ? el : i18next.t("rvm.noValue.label"),
        value: el ? el : "",
      };
    })
    .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
};

const filterAddressOptions = (filteredData: any[]) => {
  return [
    ...new Set(filteredData.map((el) => locationAddressToString(el.location))),
  ]
    .map((el) => {
      return {
        label: el ? el : i18next.t("rvm.noValue.label"),
        value: el ? el : "",
      };
    })
    .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
};

const filterLocationTypeOptions = (filteredData: any[]) => {
  return [...new Set(filteredData.map((el) => el.location.locationType))]
    .map((el) => {
      return {
        label: el ? el : i18next.t("rvm.noValue.label"),
        value: el ? el : "",
      };
    })
    .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
};

const filterDivisionOptions = (filteredData: any[]) => {
  return [...new Set(filteredData.map((el) => el.location.divisionName))]
    .map((el) => {
      return {
        label: el ? el : i18next.t("rvm.noValue.label"),
        value: el ? el : "",
      };
    })
    .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
};

export const loadPropertyInfoRoutine = createAsyncRoutine<
  {
    clientId: number;
    onSuccess?: () => void;
  },
  void,
  any[],
  any
>("valueMonitoringPage/loadPropertyInfos");

export const getPropertyInfoRoutine = createAsyncRoutine<
  { clientId: string; locationId: string; propertyInformationId: string },
  void,
  any[],
  any
>("valueMonitoringPage/getPropertyInfos");

export const getLastPropertyInfoRoutine = createAsyncRoutine<
  { clientId: string; locationId: string },
  void,
  any[],
  any
>("valueMonitoringPage/getLastPropertyInfos");

export const loadPropertyInfoForClientRoutine = createAsyncRoutine<
  {
    clientId: string;
    onSuccess?: () => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any[],
  any
>("valueMonitoringPage/loadPropertyInfosForClient");

export const loadPropertyInfoForLocationRoutine = createAsyncRoutine<
  {
    clientId: number;
    locationId: number;
    propertyInformationId: number;
  },
  void,
  any,
  any
>("valueMonitoringPage/loadPropertyInfosForLocation");

export const updatePropertyInfoActionsRoutine = createAsyncRoutine<
  {
    clientId: string;
    locationId: string;
    propertyInfoId: string;
    completed?: boolean;
    formObject: any;

    onSuccess?: () => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any,
  any
>("valueMonitoringPage/updatePropertyInfo");

export const approvePropertyInfoActionsRoutine = createAsyncRoutine<
  {
    clientId: string;
    locationId: string;
    propertyInfoId: string;
    rowVersion: string;
    comment: string;

    onSuccess?: (clientId, locationId, propertyInformationId) => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any,
  any
>("valueMonitoringPage/approvePropertyInfo");

export const rejectPropertyInfoActionsRoutine = createAsyncRoutine<
  {
    clientId: string;
    locationId: string;
    propertyInfoId: string;
    rowVersion: string;
    comment: string;

    onSuccess?: () => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any,
  any
>("valueMonitoringPage/rejectPropertyInfo");

export const deletePropertyInfoRoutine = createAsyncRoutine<
  {
    clientId: number;
    locationId: number;
    propertyInfoId: number;
    comment: string;
    rowVersion: string;
    onSuccess?: () => void;
    onError?: (err: AxiosError<any>) => void;
  },
  void,
  any,
  any
>("valueMonitoringPage/deletePropertyInfo");

export const {
  actions: {
    setClientName,
    setClientNameForSingleClient,
    setDivision,
    setLocation,
    setHistoryData,
    setFormObject,
    setCompanyCode,
    setLocationType,
    setShowActiveLocationsOnly,
    resetSearchForm,
    resetState,
    setDeleteDialog,
    hideDeletePropertyInfoDialog,
    setAddLocationDialog,
    openAddLocationDialog,
    hideAddLocationDialog,
    setShowInvitationForm,
    setShowReminderForm,
    setAddress,
    setExcelDialog,
    setExcelReportResponse,
  },
  reducer: valueMonitoringReducer,
} = valueMonitoringPageSlice;

function* updatePropertyInfoSaga() {
  yield takeEvery(
    updatePropertyInfoActionsRoutine.trigger.type,
    function* (a: ReturnType<typeof updatePropertyInfoActionsRoutine.trigger>) {
      const { onSuccess, onError } = a.payload;
      try {
        yield put(updatePropertyInfoActionsRoutine.loading());
        if (a.payload.completed !== undefined && a.payload.completed !== null) {
          const res = yield call(API.completePropertInfo, {
            ...a.payload,
            completed: a.payload.completed,
          });
          yield put(updatePropertyInfoActionsRoutine.success(res.data));
        } else {
          const res = yield call(API.updatePropertyInfo, a.payload);
          yield put(updatePropertyInfoActionsRoutine.success(res.data));
        }
        toast.success(i18next.t("rvm.success"));
        onSuccess && onSuccess();
      } catch (err: any) {
        yield put(updatePropertyInfoActionsRoutine.error(err));

        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

export function* generateReportSaga() {
  yield takeEvery(
    generateReportActions.trigger,
    function* (a: ReturnType<typeof generateReportActions.trigger>) {
      const { onSuccess, onError } = a.payload;
      try {
        yield put(generateReportActions.loading());
        const res = yield call(API.generateExternalReportDocument);
        yield put(generateReportActions.success(res.data));
        //toast.success(i18next.t("bfm.success"));
        onSuccess && onSuccess();
      } catch (err) {
        yield put(generateReportActions.error(err));
        handleAxiosError(err);
        onError && onError();
      }
    }
  );
}

function* approvePropertyInfoSaga() {
  yield takeEvery(
    approvePropertyInfoActionsRoutine.trigger.type,
    function* (
      a: ReturnType<typeof approvePropertyInfoActionsRoutine.trigger>
    ) {
      const { onSuccess, onError } = a.payload;
      try {
        yield put(approvePropertyInfoActionsRoutine.loading());
        const res = yield call(API.approvePropertyInfo, a.payload);
        yield put(approvePropertyInfoActionsRoutine.success(res.data));

        // yield put(
        //   setLocation({
        //     item: {
        //       value: a.payload.locationId,
        //       label: a.payload.locationName,
        //     },
        //   })
        // );
        // yield put(
        //   loadPropertyInfoForLocationRoutine.trigger({
        //     clientId: Number(a.payload.clientId),
        //     locationId: Number(a.payload.locationId),
        //     propertyInformationId: Number(a.payload.propertyInfoId),
        //   })
        // );
        toast.success(i18next.t("rvm.success"));
        onSuccess &&
          onSuccess(
            res.data.clientId,
            res.data.locationId,
            res.data.propertyInformationId
          );
      } catch (err: any) {
        yield put(approvePropertyInfoActionsRoutine.error(err));
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

function* rejectPropertyInfoSaga() {
  yield takeEvery(
    rejectPropertyInfoActionsRoutine.trigger.type,
    function* (a: ReturnType<typeof rejectPropertyInfoActionsRoutine.trigger>) {
      const { onSuccess, onError } = a.payload;
      try {
        yield put(rejectPropertyInfoActionsRoutine.loading());
        const res = yield call(API.rejectPropertyInfo, a.payload);
        yield put(rejectPropertyInfoActionsRoutine.success(res.data));
        // yield put(
        //   loadPropertyInfoForLocationRoutine.trigger({
        //     clientId: Number(a.payload.clientId),
        //     locationId: Number(a.payload.locationId),
        //     propertyInformationId: Number(a.payload.propertyInfoId),
        //   })
        // );
        toast.success(i18next.t("rvm.success"));
        onSuccess && onSuccess();
      } catch (err: any) {
        yield put(rejectPropertyInfoActionsRoutine.error(err));
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

function* addPropertyInfoSaga() {
  yield takeEvery(
    addPropertyInfoActionsRoutine.trigger.type,
    function* (a: ReturnType<typeof addPropertyInfoActionsRoutine.trigger>) {
      const { onSuccess, onError } = a.payload;
      try {
        yield put(addPropertyInfoActionsRoutine.loading());
        const res = yield call(API.addPropertyInfo, a.payload);
        yield put(addPropertyInfoActionsRoutine.success(res.data));
        toast.success(i18next.t("rvm.success"));
        onSuccess &&
          onSuccess(
            res.data[0].clientId,
            res.data[0].locationId,
            res.data[0].propertyInformationId
          );
      } catch (err: any) {
        yield put(addPropertyInfoActionsRoutine.error(err));
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

function* addLocationSaga() {
  yield takeEvery(
    addLocationRoutine.trigger.type,
    function* (a: ReturnType<typeof addLocationRoutine.trigger>) {
      const { onSuccess, onError, clientId, formObject } = a.payload;
      try {
        yield put(addLocationRoutine.loading());
        const res = yield call(API.addLocation, {
          clientId,
          formObject,
        });
        yield put(addLocationRoutine.success(res.data));
        toast.success(i18next.t("rvm.success"));
        onSuccess && onSuccess(res.data.location);
      } catch (err: any) {
        yield put(addLocationRoutine.error(err));
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

function* sendReminderSaga() {
  yield takeEvery(
    sendReminderRoutine.trigger.type,
    function* (a: ReturnType<typeof sendReminderRoutine.trigger>) {
      const { onSuccess, onError } = a.payload;
      try {
        yield put(sendReminderRoutine.loading());
        const res = yield call(API.sendReminder, a.payload);
        yield put(sendReminderRoutine.success(res.data));
        toast.success(i18next.t("rvm.success"));
        onSuccess && onSuccess();
      } catch (err: any) {
        yield put(sendReminderRoutine.error(err));
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

function* deletePropertyInfoSaga() {
  yield takeEvery(
    deletePropertyInfoRoutine.trigger.type,
    function* (a: ReturnType<typeof deletePropertyInfoRoutine.trigger>) {
      const {
        clientId,
        locationId,
        propertyInfoId,
        comment,
        rowVersion,
        onSuccess,
        onError,
      } = a.payload;
      try {
        yield put(deletePropertyInfoRoutine.loading());
        const res = yield call(API.deletePropertyInfo, {
          clientId,
          locationId,
          propertyInfoId,
          comment,
          rowVersion,
        });
        yield put(deletePropertyInfoRoutine.success(res.data));
        toast.success(i18next.t("rvm.success"));
        onSuccess && onSuccess();
      } catch (err: any) {
        yield put(deletePropertyInfoRoutine.error(err));
        handleAxiosError(err);
        onError && onError(err);
      }
    }
  );
}

function* loadPropertyInfoSaga() {
  yield takeEvery(
    loadPropertyInfoRoutine.trigger,
    function* (a: ReturnType<typeof loadPropertyInfoRoutine.trigger>) {
      const { onSuccess } = a.payload;
      try {
        yield put(loadPropertyInfoRoutine.loading());
        const res = yield call(API.getPropertyInfos);
        yield put(loadPropertyInfoRoutine.success(res.data));
        onSuccess && onSuccess();
      } catch (err: any) {
        yield put(loadPropertyInfoRoutine.error(err));
        handleAxiosError(err);
      }
    }
  );
}

function* loadPropertyInfoForClientSaga() {
  yield takeEvery(
    loadPropertyInfoForClientRoutine.trigger,
    function* (a: ReturnType<typeof loadPropertyInfoForClientRoutine.trigger>) {
      const { onSuccess } = a.payload;
      try {
        yield put(loadPropertyInfoForClientRoutine.loading());
        const res = yield call(API.getPropertyInfosForClient, a.payload);
        yield put(loadPropertyInfoForClientRoutine.success(res.data));
        onSuccess && onSuccess();
      } catch (err: any) {
        yield put(loadPropertyInfoForClientRoutine.error(err));
        handleAxiosError(err);
      }
    }
  );
}

function* getPropertyInfoSaga() {
  yield takeEvery(
    getPropertyInfoRoutine.trigger,
    function* (a: ReturnType<typeof getPropertyInfoRoutine.trigger>) {
      try {
        yield put(getPropertyInfoRoutine.loading());
        const res = yield call(API.getPropertyInfo, a.payload);
        yield put(getPropertyInfoRoutine.success(res.data));
      } catch (err: any) {
        yield put(getPropertyInfoRoutine.error(err));
        handleAxiosError(err);
      }
    }
  );
}

function* getLastPropertyInfoSaga() {
  yield takeEvery(
    getLastPropertyInfoRoutine.trigger,
    function* (a: ReturnType<typeof getLastPropertyInfoRoutine.trigger>) {
      try {
        yield put(getLastPropertyInfoRoutine.loading());
        const res = yield call(API.getLastPropertyInfo, a.payload);
        yield put(getLastPropertyInfoRoutine.success(res.data));
      } catch (err: any) {
        yield put(getLastPropertyInfoRoutine.error(err));
        handleAxiosError(err);
      }
    }
  );
}

function* loadPropertyInfosForLocationSaga() {
  yield takeEvery(
    loadPropertyInfoForLocationRoutine.trigger,
    function* (
      a: ReturnType<typeof loadPropertyInfoForLocationRoutine.trigger>
    ) {
      try {
        yield put(loadPropertyInfoForLocationRoutine.loading());
        const { clientId, locationId, propertyInformationId } = a.payload;
        const res = yield call(
          API.getPropertyInfosForLocation,
          clientId,
          locationId,
          0
        );
        yield put(
          loadPropertyInfoForLocationRoutine.success({
            data: res.data,
            propertyInformationId,
          })
        );
      } catch (err: any) {
        yield put(loadPropertyInfoForLocationRoutine.error(err));
        handleAxiosError(err);
      }
    }
  );
}

export function* valueMonitoringSaga() {
  yield fork(getLastPropertyInfoSaga);
  yield fork(loadPropertyInfoSaga);
  yield fork(loadPropertyInfosForLocationSaga);
  yield fork(loadPropertyInfoForClientSaga);
  yield fork(updatePropertyInfoSaga);
  yield fork(approvePropertyInfoSaga);
  yield fork(rejectPropertyInfoSaga);
  yield fork(deletePropertyInfoSaga);
  yield fork(addPropertyInfoSaga);
  yield fork(sendReminderSaga);
  yield fork(addLocationSaga);
  yield fork(getPropertyInfoSaga);
  yield fork(generateReportSaga);
}
