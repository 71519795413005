import { useTheme } from "@emotion/react";
import { mergeStyleSets } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ToolbarButton from "../../../components/controls/ToolbarButton";
import { BackButton } from "../../../components/navigation/BackButton";
import { CancelButton } from "../../../components/navigation/CancelButton";
import { Flex, Right } from "../../../components/styled";
import { routes, useCurrentRoute } from "../../../config/routes";
import useIsExternalUser from "../../../hooks/useIsExternalUser";
import useIsInternalUser from "../../../hooks/useIsInternalUser";
import { useSelector } from "../../../store/hooks";
import { history } from "../../../store/store";
import {
  setShowInvitationForm,
  setShowReminderForm,
} from "../../../store/valueMonitoring/valueMonitoringPage";
import ExternalUserCommandBar from "./ExternalUserCommandBar";

type Props = {
  handleShowApproveForm: (value: boolean) => void;
  handleShowRejectForm: (value: boolean) => void;
  handleShowSubmitForm: (value: boolean) => void;
  formProps: any;
  handleSetSubmit: (value: boolean) => void;
  submitDisabled: boolean;
};

function ValueMonitoringHeader({
  formProps,
  handleShowApproveForm,
  handleShowRejectForm,
  handleShowSubmitForm,
  handleSetSubmit,
  submitDisabled,
}: Props) {
  const { clientId, locationId } = useParams() as any;
  const { t } = useTranslation();

  const isInternalUser = useIsInternalUser();
  const isExternalUser = useIsExternalUser();
  const clientName = useSelector((s) => s.valueMonitoring.clientName);

  const theme = useTheme();
  const classes = getClassNames(theme);
  const formObject = useSelector((s) => s.valueMonitoring.formObject);
  const selectedLocation = useSelector(
    (s) => s.valueMonitoring.selectedLocation
  );

  const campaignInfo = useSelector((s) => s.campaign.campaignInfo);
  const campaignInfoLoading = useSelector(
    (s) => s.campaign.campaignDataLoading
  );
  const campaignIsClosed = campaignInfo?.isCampaignClosed || !campaignInfo;
  const taxonomy = useSelector((s) => s.taxonomy);

  const route = useCurrentRoute();
  const dispatch = useDispatch();

  const isEditMode = useMemo(() => {
    return route === "valueMonitoringForLocationEdit";
  }, [route]);

  const canSendInvitation: boolean = useMemo(() => {
    if (
      !formObject ||
      typeof formObject === "undefined" ||
      !selectedLocation ||
      typeof selectedLocation === "undefined" ||
      campaignIsClosed
    )
      return false;
    const locationStatusCodeCheck = selectedLocation
      ? taxonomy.LocationStatus.byId[selectedLocation.location.locationStatus]
          ?.code !== "LocationStatus.DevistedSold"
      : true;
    return (
      (taxonomy?.PropertyInformationStatus.byId[formObject.statusCode].code ===
        "PropertyInformationStatus.DONE" ||
        taxonomy?.PropertyInformationStatus.byId[formObject.statusCode].code ===
          "PropertyInformationStatus.NO_DATA") &&
      route !== "valueMonitoringForLocationEdit" &&
      locationStatusCodeCheck
    );
  }, [formObject, taxonomy, route, selectedLocation]);

  const canSendReminder: boolean = useMemo(() => {
    if (
      !formObject ||
      typeof formObject === "undefined" ||
      !selectedLocation ||
      typeof selectedLocation === "undefined"
    )
      return false;
    const locationStatusCodeCheck = selectedLocation
      ? taxonomy.LocationStatus.byId[selectedLocation.location.locationStatus]
          ?.code !== "LocationStatus.DevistedSold"
      : true;
    return (
      taxonomy?.PropertyInformationStatus.byId[formObject.statusCode].code ===
        "PropertyInformationStatus.INVITATION_SENT" &&
      route !== "valueMonitoringForLocationEdit" &&
      locationStatusCodeCheck
    );
  }, [formObject, taxonomy, route, selectedLocation]);

  const isInvitationSent = formObject
    ? taxonomy.PropertyInformationStatus.byId[formObject.statusCode].code ===
      "PropertyInformationStatus.INVITATION_SENT"
    : false;

  const isReview = formObject
    ? taxonomy.PropertyInformationStatus.byId[formObject.statusCode].code ===
      "PropertyInformationStatus.REVIEW"
    : false;

  const isDisabledStatus = formObject
    ? taxonomy.PropertyInformationStatus.byId[
        selectedLocation?.location?.statusCode
      ]?.code === "PropertyInformationStatus.DISABLED"
    : false;

  const isReadOnly =
    (!isInvitationSent && isExternalUser) ||
    (isInvitationSent && isInternalUser);

  return (
    <div className={classes.navigation}>
      {isInternalUser ? isEditMode ? <CancelButton /> : <BackButton /> : null}
      {/* {isExternalUser && (
        <div style={{ width: "670px" }}>
          <CommandBar items={commandBarItems as any} overflowItems={[]} />
        </div>
      )} */}
      {isExternalUser && <ExternalUserCommandBar />}
      <Right>
        {isExternalUser && (
          <div
            style={{
              fontSize: 14,
            }}
          >
            {clientName ? (
              campaignInfoLoading ? (
                <div>{t("rvm.campaignStatusLoading.label")}</div>
              ) : (
                <Flex>
                  {campaignIsClosed
                    ? t("rvm.campaignIsClosed.label")
                    : t("rvm.campaignIsOpen.label")}
                </Flex>
              )
            ) : null}
          </div>
        )}
        {isInternalUser && formObject && !formObject.isBindToLocalLocation ? (
          <>
            <ToolbarButton
              operationFn={(e) => {
                dispatch(setShowInvitationForm(true));
              }}
              className={classes.cancelBtn}
              iconName={"Send"}
              text={t("rvm.sendInvitation.label")}
              disabled={!canSendInvitation}
            />

            <ToolbarButton
              operationFn={(e) => {
                dispatch(setShowReminderForm(true));
              }}
              className={classes.cancelBtn}
              iconName={"MailReminder"}
              text={t("rvm.sendReminder.label")}
              disabled={!canSendReminder}
            />
          </>
        ) : null}

        {formObject && !isReadOnly && !isEditMode && !isExternalUser ? (
          <ToolbarButton
            disabled={isDisabledStatus}
            iconName={"SingleColumnEdit"}
            text={t("rvm.edit.label")}
            operationFn={(e) => {
              history.push(
                routes.valueMonitoringForLocationEdit.getPath(
                  clientId,
                  locationId,
                  formObject.propertyInformationId
                )
              );
            }}
          />
        ) : null}
        {formObject &&
        (isExternalUser || isEditMode) &&
        ((isInvitationSent && isExternalUser) ||
          (!isInvitationSent && isInternalUser)) ? (
          <>
            <ToolbarButton
              disabled={submitDisabled}
              iconName={"Save"}
              text={t("greco.save")}
              operationFn={() => {
                handleSetSubmit(false);
                formProps.submitForm();
              }}
            />
            {isExternalUser ? (
              <ToolbarButton
                disabled={submitDisabled}
                iconName={"SaveAndClose"}
                text={t("rvm.navBar.saveAndNotify")}
                operationFn={() => {
                  // setSubmit(false);
                  // props.submitForm();
                  formProps.validateForm().then((errorsObj) => {
                    if (Object.keys(errorsObj).length === 0) {
                      handleShowSubmitForm(true);
                    }
                  });
                }}
              />
            ) : null}
          </>
        ) : null}
        {formObject &&
        !formObject.isBindToLocalLocation &&
        isReview &&
        isInternalUser &&
        !isEditMode ? (
          <>
            <ToolbarButton
              disabled={isDisabledStatus}
              iconName={"DocumentApproval"}
              text={t("rvm.approve.label")}
              operationFn={(e) => {
                handleShowApproveForm(true);
              }}
            />
            <ToolbarButton
              disabled={campaignIsClosed || isDisabledStatus}
              iconName={"DocumentReply"}
              text={t("rvm.reject.label")}
              operationFn={(e) => {
                handleShowRejectForm(true);
              }}
            />
          </>
        ) : null}
      </Right>
    </div>
  );
}

export default ValueMonitoringHeader;

const getClassNames = (theme) =>
  mergeStyleSets({
    navigation: {
      background: theme.palette.white,
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "0 25px",
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      position: "sticky",
      top: 0,
      zIndex: 999,
      minHeight: 44,
    },
    cancelBtn: {
      display: "flex",
      alignItems: "center",
      height: 32,
      ".ms-Button-icon": {
        height: "inherit",
        display: "flex",
        alignItems: "center",
      },
    },
  });
