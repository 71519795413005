import { Form, Formik } from "formik";
import { useCallback, useMemo, useState } from "react";

import { formatISO } from "date-fns";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../../../components/FormSpinner";
import useIsExternalUser from "../../../../hooks/useIsExternalUser";
import { delay } from "../../../../library/delay";
import { useSelector } from "../../../../store/hooks";
import {
  loadPropertyInfoForClientRoutine,
  setLocation,
  updatePropertyInfoActionsRoutine,
} from "../../../../store/valueMonitoring/valueMonitoringPage";
import { formBase64String, stringToNumber } from "../../../../utils/utils";
import FilterFields from "../../form/FilterFields";
import { soldTaxonomyValue } from "../../form/constants";
import useValidationSchema from "../../hooks/useValidationSchema";
import ValueMonitoringHeader from "../ValueMonitoringHeader";
import MainContent from "./MainContent/MainContent";

type Props = {
  formRef: any;
  handleShowApproveForm: (value: boolean) => void;
  handleShowRejectForm: (value: boolean) => void;
  handleShowSubmitForm: (value: boolean) => void;
  file: File | null;
  filename: string;
  handleSetFile: (file: File) => void;
  handleSetFilename: (value: string) => void;
};

function ValueMonitoringForm({
  formRef,
  handleShowApproveForm,
  handleShowRejectForm,
  handleShowSubmitForm,
  file,
  filename,
  handleSetFile,
  handleSetFilename,
}: Props) {
  const { validationSchema, handleUpdateSchema } = useValidationSchema();

  const dispatch = useDispatch();
  const formObject = useSelector((s) => s.valueMonitoring.formObject);
  const selectedLocation = useSelector(
    (s) => s.valueMonitoring.selectedLocation
  );

  const dataLoadStatus = useSelector((s) => s.valueMonitoring.dataLoadStatus);

  const [submit, setSubmit] = useState(false);

  const handleSetSubmit = useCallback((value: boolean) => {
    setSubmit(value);
  }, []);

  const isExternalUser = useIsExternalUser();

  const clientInternalNumber = useSelector(
    (s) => s.valueMonitoring.selectedLocation?.client?.clientInternalNumber
  );
  const stockValuesExcludedClients =
    process.env.REACT_APP_EXCLUDE_STOCKVALUES_FIELD_CLIENTS;

  const isStockValuesFieldExcluded = useMemo(() => {
    const retVal = stockValuesExcludedClients
      ? stockValuesExcludedClients
          ?.split(",")
          .includes(clientInternalNumber + "")
      : true;

    return retVal;
  }, [clientInternalNumber, stockValuesExcludedClients]);

  const prepareFileForPayload = async (file) => {
    if (!file) return;
    return await {
      name: file.name,
      base64String: await formBase64String(file),
    };
  };

  const handleSubmit = async (values) => {
    let reportDocument = await prepareFileForPayload(file);
    dispatch(
      updatePropertyInfoActionsRoutine.trigger({
        clientId: formObject.clientId,
        locationId: formObject.locationId,

        propertyInfoId: formObject.propertyInformationId,
        completed: isExternalUser ? submit : undefined,
        formObject: {
          reportDocument,
          currencyCode: values.currencyCode?.value,
          buildingValues: stringToNumber(values.buildingValues),
          buildingValuesComment:
            values.buildingValuesComment?.value || values.buildingValuesComment,

          equipmentValues: stringToNumber(values.equipmentValues),
          equipmentValuesComment:
            values.equipmentValuesComment?.value ||
            values.equipmentValuesComment,

          stockValues: isStockValuesFieldExcluded
            ? null
            : stringToNumber(values.stockValues),
          totalPd: stringToNumber(values.totalPd),
          businessInteruptionValues12Months: stringToNumber(
            values.businessInteruptionValues12Months
          ),
          totalPdBi: stringToNumber(values.totalPdBi),
          indemnityPeriodCode: values.indemnityPeriodCode?.value,
          businessInteruptionValuesBasedOnIndemnityPeriod: stringToNumber(
            values.businessInteruptionValuesBasedOnIndemnityPeriod
          ),
          businessInteruptionValues12MonthsComment:
            values.businessInteruptionValues12MonthsComment?.value ||
            values.businessInteruptionValues12MonthsComment,
          totalPdBiBasedOnIndemnityPeriod: stringToNumber(
            values.totalPdBiBasedOnIndemnityPeriod
          ),
          clientComment: formObject.clientComment,
          grECoComment: formObject.grECoComment,
          //grECoComment: formObject.GrECoComment,
          rowVersion: formObject.rowVersion,
          coveragePeriodFromDate: values.coveragePeriodFromDate
            ? formatISO(values.coveragePeriodFromDate, {
                representation: "date",
              })
            : null,
          coveragePeriodToDate: values.coveragePeriodToDate
            ? formatISO(values.coveragePeriodToDate, {
                representation: "date",
              })
            : null,
          desiredLocationOwningStatusCode:
            values.desiredLocationOwningStatusCode?.value,
          desiredLocationOwningStatusDate:
            values.desiredLocationOwningStatusDate
              ? formatISO(values.desiredLocationOwningStatusDate, {
                  representation: "date",
                })
              : "",
          desiredLocationStatusCode: values.desiredLocationStatusCode?.value,
          desiredLocationStatusCodeComment:
            values.desiredLocationStatusCodeComment?.value ||
            values.desiredLocationStatusCodeComment,
          desiredLocationStatusDate: values.desiredLocationStatusDate
            ? formatISO(values.desiredLocationStatusDate, {
                representation: "date",
              })
            : "",
          documentPath: filename,
        },
        onSuccess: () => {
          dispatch(
            loadPropertyInfoForClientRoutine.trigger({
              clientId: formObject.clientId,
              onSuccess: () => {
                dispatch(
                  setLocation({
                    item: {
                      value: formObject.locationId,
                      label: selectedLocation.location.locationName,
                    },
                  })
                );
              },
            })
          );
        },
      })
    );
    handleSetFile(null);
    //}
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        validationSchema={validationSchema}
        initialValues={{} as any}
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          handleSubmit(values);
          await delay(400);
          setSubmitting(false);
        }}
      >
        {(props) => {
          const formValues = props?.values;
          const desiredLocationStatusCode =
            props.values.desiredLocationStatusCode;

          const hasFinancialData =
            stringToNumber(formValues?.buildingValues) ||
            stringToNumber(formValues?.equipmentValues) ||
            (isStockValuesFieldExcluded
              ? false
              : stringToNumber(formValues?.stockValues)) ||
            stringToNumber(formValues?.businessInteruptionValues12Months);

          const submitDisabled =
            hasFinancialData &&
            desiredLocationStatusCode?.value === soldTaxonomyValue;

          return (
            <Form>
              <ValueMonitoringHeader
                submitDisabled={submitDisabled}
                formProps={props}
                handleShowApproveForm={handleShowApproveForm}
                handleShowRejectForm={handleShowRejectForm}
                handleShowSubmitForm={handleShowSubmitForm}
                handleSetSubmit={handleSetSubmit}
              />

              <FilterFields />

              {formObject !== null && selectedLocation !== null ? (
                <MainContent
                  handleUpdateSchema={handleUpdateSchema}
                  file={file}
                  filename={filename}
                  setFile={handleSetFile}
                  setFilename={handleSetFilename}
                  formProps={props}
                />
              ) : dataLoadStatus === "loading" ? (
                <FormSpinner />
              ) : null}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default ValueMonitoringForm;
