import {
  Customizer,
  DirectionalHint,
  IStyleSet,
  TooltipHost,
} from "@fluentui/react";
import { getTheme, mergeStyleSets } from "@uifabric/styling";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext, IAppContext } from "../../AppContext";

type Props = {
  children: React.ReactNode;
  tooltipText?: string;
  isTooltipHidden?: boolean;
  isReadOnly?: boolean;
  styles?: IStyleSet;
};
const customize = {
  Tooltip: {
    styles: {
      root: {
        border: "1px solid #eeeeee",
      },
    },
  },
};
export const FieldContainer = ({
  tooltipText,
  isTooltipHidden,
  children,
  isReadOnly,
  styles,
}: Props) => {
  const theme = getTheme();
  const appContext = useContext<IAppContext>(AppContext);
  const { t } = useTranslation();

  const defaultStyles = {
    root: {
      marginBottom: isReadOnly ? 0 : theme.spacing.m,
    },
  };
  // const [tooltipsStatus, setTooltipsStatus] = useLocalStorage(
  //   "tooltipsStatus",
  //   false
  // );

  const classNames = styles
    ? mergeStyleSets(defaultStyles, styles)
    : mergeStyleSets(defaultStyles);
  let tooltipTranslatedText = t(tooltipText.replaceAll(".label", ".tooltip"));
  if (tooltipTranslatedText.includes(".tooltip")) {
    return <div className={classNames.root}>{children}</div>;
  }
  return (
    <div className={classNames.root}>
      <Customizer scopedSettings={customize}>
        <TooltipHost
          content={tooltipTranslatedText}
          directionalHint={DirectionalHint.rightCenter}
          hidden={!!!appContext.tooltipsStatus}
        >
          {children}
        </TooltipHost>
      </Customizer>
    </div>
  );
};
