import styled from "@emotion/styled";
import { SearchBox } from "@fluentui/react";

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
} & {
  [key: string]: any;
};

export let NavSearch = ({
  value,
  onChange,
  placeholder,
  className,
  ...otherProps
}: Props) => {
  return (
    <SearchBoxContainer className={className} {...otherProps}>
      <StyledSearchBox
        placeholder={placeholder}
        value={value}
        onChange={(e, value) => onChange(value || "")}
      />
    </SearchBoxContainer>
  );
};

const SearchBoxContainer = styled.div`
  .ms-SearchBox {
    border: 1px solid ${(p) => p.theme.palette.neutralTertiaryAlt};
    border-radius: 2px;
  }
  width: 200px;
`;

const StyledSearchBox = styled(SearchBox)`
  border: 0;
`;
