import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";
import { Label } from "@fluentui/react";
import { Select } from "@greco/components";
import { useField } from "formik";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RefContext } from "../../contexts/RefProvider";
import { useSelector } from "../../store/hooks";
import { VSpace } from "../Spacer";
import { ErrorMessage } from "../styled";
import { TaxonomyFieldConfig } from "./types";
import { useDynamicConfig } from "./useDynamicConfig";

type Props = { fieldConfig: TaxonomyFieldConfig };

export const TaxonomyField = ({ fieldConfig }: Props) => {
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const {
    name,
    label,
    isVisible = true,
    isDisabled,
    taxonomyKey,
    isRequired,
    isSearchable,
    validate,
    include,
    exclude,
    renderField,
    background,
  } = config;
  const [field, { error }, { setValue }] = useField({
    name,
    validate,
  });
  const taxonomy = useSelector((s) => s.taxonomy);
  const { setRef } = useContext(RefContext);
  const { t } = useTranslation();
  const theme = useTheme();

  const options = useMemo(() => {
    const options = taxonomy[taxonomyKey].items
      .filter((tax) => {
        if (include) {
          return include.includes(tax.code);
        } else {
          return true;
        }
      })
      .filter((tax) => {
        if (exclude) {
          return !exclude.includes(tax.code);
        } else {
          return true;
        }
      })
      .map((tax) => {
        return {
          value: tax.id,
          label: t(tax.code),
        };
      });
    return options;
  }, [t, taxonomy[taxonomyKey].items, include, exclude]);

  if (!isVisible) return null;

  const fieldRender = (
    <Select
      id={name}
      options={options as any}
      isSearchable={isSearchable}
      {...field}
      onChange={(value) => setValue(value)}
      placeholder={
        isDisabled
          ? ""
          : isSearchable
          ? t("greco.form.searchPlaceholder")
          : t("greco.form.selectPlaceholder")
      }
      noOptionsMessage={() => t("greco.noResults")}
      isDisabled={isDisabled}
      isClearable
      selectRef={setRef(name)}
      inputId={name}
    />
  );

  return (
    <div
      className={css`
        flex: 1;
      `}
    >
      <Label required={isRequired} disabled={isDisabled}>
        {label}
      </Label>
      {renderField ? renderField({ field: fieldRender }) : fieldRender}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <VSpace height={10} />
    </div>
  );
};
