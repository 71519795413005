import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../../store/hooks";
import { formatNumber } from "../../../../utils/number";

function useSetInitialFormValues() {
  const { t } = useTranslation();
  const formObject = useSelector((s) => s.valueMonitoring.formObject);
  const taxonomy = useSelector((s) => s.taxonomy);

  const { setValues }: { setValues } = useFormikContext();

  const clientInternalNumber = useSelector(
    (s) => s.valueMonitoring.selectedLocation?.client?.clientInternalNumber
  );
  const stockValuesExcludedClients =
    process.env.REACT_APP_EXCLUDE_STOCKVALUES_FIELD_CLIENTS;

  const isStockValuesFieldExcluded = useMemo(() => {
    const retVal = stockValuesExcludedClients
      ? stockValuesExcludedClients
          ?.split(",")
          .includes(clientInternalNumber + "")
      : true;

    return retVal;
  }, [clientInternalNumber, stockValuesExcludedClients]);

  useEffect(() => {
    let initialValuesObj = {};
    if (formObject === null || formObject.length === 0) {
      setValues(initialValuesObj);
      return;
    }
    const historyItem = formObject;
    initialValuesObj["coveragePeriodFromDate"] =
      historyItem.coveragePeriodFromDate
        ? new Date(historyItem.coveragePeriodFromDate)
        : undefined;
    initialValuesObj["coveragePeriodToDate"] = historyItem.coveragePeriodToDate
      ? new Date(historyItem.coveragePeriodToDate)
      : undefined;

    initialValuesObj["currencyCode"] = historyItem.currencyCode
      ? {
          value: historyItem.currencyCode,
          label: t(taxonomy?.Currency?.byId[historyItem.currencyCode]?.code),
        }
      : null;

    initialValuesObj["buildingValues"] = formatNumber(
      historyItem.buildingValues
    );
    initialValuesObj["equipmentValues"] = formatNumber(
      historyItem.equipmentValues
    );
    initialValuesObj["stockValues"] = isStockValuesFieldExcluded
      ? null
      : formatNumber(historyItem.stockValues);

    initialValuesObj["totalPd"] = formatNumber(historyItem.totalPd);
    initialValuesObj["businessInteruptionValues12Months"] = formatNumber(
      historyItem.businessInteruptionValues12Months
    );
    initialValuesObj["totalPdBi"] = formatNumber(historyItem.totalPdBi);

    initialValuesObj["indemnityPeriodCode"] = historyItem.indemnityPeriodCode
      ? {
          value: historyItem.indemnityPeriodCode,
          label: t(
            taxonomy?.IndemnityPeriod?.byId[historyItem.indemnityPeriodCode]
              ?.code
          ),
        }
      : null;

    initialValuesObj["businessInteruptionValuesBasedOnIndemnityPeriod"] =
      formatNumber(historyItem.businessInteruptionValuesBasedOnIndemnityPeriod);
    initialValuesObj["totalPdBiBasedOnIndemnityPeriod"] = formatNumber(
      historyItem.totalPdBiBasedOnIndemnityPeriod
    );
    initialValuesObj["clientComment"] = historyItem.clientComment;
    initialValuesObj["grECoComment"] = historyItem.grECoComment;

    initialValuesObj["desiredLocationOwningStatusCode"] =
      historyItem.desiredLocationOwningStatusCode
        ? {
            value: historyItem.desiredLocationOwningStatusCode,
            label: t(
              taxonomy?.LocationOwnershipStatus?.byId[
                historyItem.desiredLocationOwningStatusCode
              ]?.code
            ),
          }
        : null;

    initialValuesObj["desiredLocationOwningStatusDate"] =
      historyItem.desiredLocationOwningStatusDate
        ? new Date(historyItem.desiredLocationOwningStatusDate)
        : undefined;
    initialValuesObj["desiredLocationStatusCode"] =
      historyItem.desiredLocationStatusCode
        ? {
            value: historyItem.desiredLocationStatusCode,
            label: t(
              taxonomy?.LocationStatus?.byId[
                historyItem.desiredLocationStatusCode
              ]?.code
            ),
          }
        : null;

    initialValuesObj["desiredLocationStatusDate"] =
      historyItem.desiredLocationStatusDate
        ? new Date(historyItem.desiredLocationStatusDate)
        : undefined;

    initialValuesObj["buildingValuesComment"] =
      formObject?.buildingValuesComment || null;
    initialValuesObj["equipmentValuesComment"] =
      formObject?.equipmentValuesComment || null;
    initialValuesObj["businessInteruptionValues12MonthsComment"] =
      formObject?.businessInteruptionValues12MonthsComment || null;
    initialValuesObj["desiredLocationStatusCodeComment"] =
      formObject?.desiredLocationStatusCodeComment || null;
    setValues(initialValuesObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formObject, taxonomy, setValues]);
}

export default useSetInitialFormValues;
