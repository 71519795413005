import { Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../../components/FormSpinner";
import ToolbarButton from "../../../components/controls/ToolbarButton";
import { Field } from "../../../components/form/Field";
import { useSelector } from "../../../store/hooks";
import { deletePropertyInfoRoutine } from "../../../store/valueMonitoring/valueMonitoringPage";

type Props = {
  close: () => void;
};

const DeletePropertyInfoDialog = ({ close }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const clientId = useSelector((s) => s.valueMonitoring.formObject.clientId);
  const locationId = useSelector(
    (s) => s.valueMonitoring.formObject.locationId
  );
  const propertyInfoId = useSelector(
    (s) => s.valueMonitoring.formObject.propertyInformationId
  );
  const rowVersion = useSelector(
    (s) => s.valueMonitoring.formObject.rowVersion
  );
  const isLoading = useSelector(
    (s) => s.valueMonitoring.deleteDialog.isLoading
  );

  const onSubmit = async (data) => {
    await dispatch(
      deletePropertyInfoRoutine.trigger({
        clientId: clientId,
        locationId: locationId,
        rowVersion: rowVersion,
        propertyInfoId: propertyInfoId,
        comment: data.comment,
        onSuccess: () => {
          window.location.reload();
        },
      })
    );
    close();
  };

  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {t("rvm.delete.label")}
    </div>
  );

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        // className: cssClass`
        //   .ms-Dialog-inner {
        //     padding-bottom: 14px;
        //   }
        //   .ms-Dialog-title {
        //     padding-bottom: 10px;
        //   }
        // `,
        showCloseButton: true,
      }}
      minWidth={400}
      modalProps={{
        isBlocking: true, //phraseActivated ? false :
      }}
    >
      <Formik
        initialValues={{ comment: "" }}
        onSubmit={onSubmit}
        validateOnChange={didTrySubmit}
        validateOnBlur={didTrySubmit}
      >
        {({ submitForm }) => {
          return (
            <>
              <Field
                name="comment"
                type="text"
                label={t("rvm.clientComment.label")}
                isMultiline
                rows={4}
                isRequired={false}
              />
              <DialogFooter>
                <ToolbarButton
                  iconName={"PageRemove"}
                  text={t("rvm.delete.label")}
                  disabled={isLoading}
                  operationFn={() => {
                    setDidTrySubmit(true);
                    submitForm();
                  }}
                />
              </DialogFooter>
              {isLoading && <FormSpinner />}
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default DeletePropertyInfoDialog;
